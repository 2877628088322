
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { Toaster } from "@/components/ui/toaster";
import { AuthProvider } from "./contexts/AuthContext";
import Index from "./pages/Index";
import NotFound from "./pages/NotFound";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import Profile from "./pages/Profile";
import ProfileSetup from "./pages/ProfileSetup";
import TradingPlatform from "./pages/TradingPlatform";
import SignalManagement from "./pages/SignalManagement";
import Blogs from "./pages/Blogs";
import BlogDetail from "./pages/BlogDetail";
import BlogManagement from "./pages/BlogManagement";
import Pricing from "./pages/Pricing";
import ProtectedRoute from "./components/ProtectedRoute";

const queryClient = new QueryClient();

const App = () => (
  <HelmetProvider>
    <Helmet>
      <title>TradingSignals - Professional Trading Platform</title>
      <meta name="description" content="Advanced trading signals and portfolio management platform for serious investors and traders." />
    </Helmet>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/auth/signin" element={<SignIn />} />
            <Route path="/auth/signup" element={<SignUp />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/blogs/:slug" element={<BlogDetail />} />
            <Route 
              path="/app" 
              element={
                <ProtectedRoute>
                  <TradingPlatform />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/admin/signals" 
              element={
                <ProtectedRoute>
                  <SignalManagement />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/admin/blogs" 
              element={
                <ProtectedRoute>
                  <BlogManagement />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/profile" 
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/profile-setup" 
              element={
                <ProtectedRoute>
                  <ProfileSetup />
                </ProtectedRoute>
              } 
            />
            {/* ADD ALL CUSTOM ROUTES ABOVE THE CATCH-ALL "*" ROUTE */}
            <Route path="*" element={<NotFound />} />
          </Routes>
          <Toaster />
          <Sonner />
        </BrowserRouter>
      </AuthProvider>
    </QueryClientProvider>
  </HelmetProvider>
);

export default App;
