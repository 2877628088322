
import { supabase } from '@/integrations/supabase/client';
import { toast } from '@/hooks/use-toast';
import { NavigateFunction } from 'react-router-dom';
import { checkIsAdmin, checkProfileComplete } from '@/utils/authUtils';

export const signUp = async (
  email: string, 
  password: string, 
  userData: any,
  navigate: NavigateFunction
) => {
  try {
    console.log("Starting signup process for:", email);
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          full_name: userData.fullName,
        },
      },
    });

    if (error) throw error;
    
    if (!data.user) {
      throw new Error("User creation failed");
    }

    console.log("User created successfully:", data.user.id);
    
    toast({
      title: "Account created successfully",
      description: "You can now sign in to your account",
    });
    
    navigate('/auth/signin');
  } catch (error: any) {
    console.error("Error in signup process:", error);
    toast({
      title: "Error creating account",
      description: error.message || "Something went wrong",
      variant: "destructive"
    });
  }
};

export const signIn = async (
  email: string, 
  password: string,
  navigate: NavigateFunction,
  setIsProfileComplete: (value: boolean) => void,
  setIsAdmin: (value: boolean) => void,
) => {
  try {
    console.log("Attempting sign in with:", email);
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) {
      console.error("Sign in error:", error);
      throw error;
    }
    
    console.log("Sign in successful:", data);
    
    const user = data.user;
    const isComplete = await checkProfileComplete(user);
    const userIsAdmin = await checkIsAdmin(user);
    
    setIsProfileComplete(isComplete);
    setIsAdmin(userIsAdmin);
    
    console.log("Is profile complete?", isComplete);
    
    if (isComplete) {
      navigate('/profile');
      toast({
        title: "Welcome back!",
        description: "You have successfully signed in.",
      });
    } else {
      navigate('/profile-setup');
      toast({
        title: "Complete your profile",
        description: "Please provide additional information to complete your profile.",
      });
    }
    
  } catch (error: any) {
    console.error("Sign in error details:", error);
    toast({
      title: "Failed to sign in",
      description: error.message || "Invalid email or password",
      variant: "destructive"
    });
    throw error;
  }
};

export const signOut = async (navigate: NavigateFunction) => {
  try {
    await supabase.auth.signOut();
    navigate('/');
    toast({
      title: "Signed out",
      description: "You have been signed out successfully.",
    });
  } catch (error: any) {
    toast({
      title: "Error signing out",
      description: error.message || "Something went wrong",
      variant: "destructive"
    });
  }
};
