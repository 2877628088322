import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Skeleton } from "@/components/ui/skeleton";
import { useSignalStats } from "@/hooks/useSignalStats";
import { ArrowUpCircle, ArrowDownCircle, Percent, CheckCircle, XCircle, AlertCircle, RefreshCw } from "lucide-react";
import { Button } from "@/components/ui/button";
const StatItem = ({
  label,
  value,
  icon,
  color
}: {
  label: string;
  value: number | null;
  icon: React.ReactNode;
  color: string;
}) => <div className="flex flex-col">
    <div className="text-sm text-muted-foreground mb-1">{label}</div>
    <div className="flex items-center">
      <span className={`mr-2 ${color}`}>{icon}</span>
      <span className="text-xl font-bold">{value ?? 0}</span>
    </div>
  </div>;
const SignalStats = () => {
  const {
    stats,
    isLoading,
    refetch
  } = useSignalStats();

  // Calculate success rate
  const successRate = React.useMemo(() => {
    if (!stats) return 0;
    const totalClosed = (stats.closed_in_profit || 0) + (stats.closed_in_loss || 0) + (stats.target_hit || 0) + (stats.stop_loss_hit || 0);
    if (totalClosed === 0) return 0;
    const successfulTrades = (stats.closed_in_profit || 0) + (stats.target_hit || 0);
    return Math.round(successfulTrades / totalClosed * 100);
  }, [stats]);
  const totalOpen = React.useMemo(() => {
    if (!stats) return 0;
    return (stats.open_in_money || 0) + (stats.open_out_of_money || 0);
  }, [stats]);
  return <Card>
      <CardHeader className="flex flex-row items-center justify-between pb-2">
        <CardTitle className="text-md font-medium">Signal Performance</CardTitle>
        
      </CardHeader>
      <CardContent>
        {isLoading ? <div className="space-y-3">
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-3/4" />
            <Skeleton className="h-4 w-2/3" />
          </div> : <div className="space-y-4">
            <div className="grid grid-cols-3 gap-4">
              <StatItem label="Total Signals" value={stats?.total_signals} icon={<Percent className="h-4 w-4" />} color="text-blue-500" />
              <StatItem label="Success Rate" value={successRate} icon={<Percent className="h-4 w-4" />} color="text-blue-500" />
              <StatItem label="Open Signals" value={totalOpen} icon={<AlertCircle className="h-4 w-4" />} color="text-orange-500" />
            </div>
            
            <div className="border-t pt-4">
              <div className="grid grid-cols-2 gap-x-4 gap-y-3">
                <StatItem label="In Money" value={stats?.open_in_money} icon={<ArrowUpCircle className="h-4 w-4" />} color="text-green-500" />
                <StatItem label="Out of Money" value={stats?.open_out_of_money} icon={<ArrowDownCircle className="h-4 w-4" />} color="text-red-500" />
                <StatItem label="Target Hit" value={stats?.target_hit} icon={<CheckCircle className="h-4 w-4" />} color="text-green-500" />
                <StatItem label="Stop Loss Hit" value={stats?.stop_loss_hit} icon={<XCircle className="h-4 w-4" />} color="text-red-500" />
                <StatItem label="Closed in Profit" value={stats?.closed_in_profit} icon={<CheckCircle className="h-4 w-4" />} color="text-green-500" />
                <StatItem label="Closed in Loss" value={stats?.closed_in_loss} icon={<XCircle className="h-4 w-4" />} color="text-red-500" />
              </div>
            </div>
          </div>}
      </CardContent>
    </Card>;
};
export default SignalStats;