
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Shield, Check, CheckCircle } from "lucide-react";
import HomeHeader from "@/components/home/HomeHeader";

const Pricing = () => {
  return (
    <div className="bg-white min-h-screen flex flex-col">
      <HomeHeader />
      
      <main className="flex-grow">
        <section className="py-12 sm:py-16 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="text-center max-w-3xl mx-auto mb-16">
              <h1 className="text-4xl font-bold text-gray-900 mb-4">Transparent pricing for every trader</h1>
              <p className="text-xl text-gray-600">
                Choose the plan that best fits your trading style and goals. 
                All plans include a 7-day free trial.
              </p>
            </div>
            
            <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
              {/* Free Trial Card */}
              <Card className="border-gray-200 shadow-sm">
                <CardContent className="p-6">
                  <div className="bg-blue-50 text-blue-700 text-xs font-semibold px-3 py-1 rounded-full inline-block mb-4">
                    FREE TRIAL
                  </div>
                  <h3 className="text-xl font-semibold mb-2">Free Trial</h3>
                  <div className="text-4xl font-bold mb-1">$0</div>
                  <p className="text-sm text-gray-500 mb-6">Try all features for 7 days</p>
                  
                  <ul className="space-y-3 mb-8">
                    <li className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span>Full access to all features</span>
                    </li>
                    <li className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span>10 daily signals</span>
                    </li>
                    <li className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span>Basic AI analysis</span>
                    </li>
                    <li className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span>Email alerts</span>
                    </li>
                  </ul>
                  
                  <Button asChild className="w-full bg-blue-600 hover:bg-blue-700">
                    <Link to="/auth/signup">Start your free trial</Link>
                  </Button>
                  <p className="text-xs text-center text-gray-500 mt-4">No credit card required</p>
                </CardContent>
              </Card>
              
              {/* Pro Card */}
              <Card className="border-blue-200 shadow-lg relative bg-white">
                <div className="absolute top-0 right-0 bg-blue-600 text-white text-xs font-semibold px-3 py-1 rounded-bl-lg">
                  POPULAR
                </div>
                <CardContent className="p-6">
                  <div className="bg-blue-50 text-blue-700 text-xs font-semibold px-3 py-1 rounded-full inline-block mb-4">
                    MOST POPULAR
                  </div>
                  <h3 className="text-xl font-semibold mb-2">Pro</h3>
                  <div className="text-4xl font-bold mb-1">$79<span className="text-base font-normal text-gray-600">/month</span></div>
                  <p className="text-sm text-gray-500 mb-6">Billed monthly or $799/year</p>
                  
                  <ul className="space-y-3 mb-8">
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-blue-600 mr-2 flex-shrink-0 mt-0.5" />
                      <span><strong>Unlimited</strong> signals</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-blue-600 mr-2 flex-shrink-0 mt-0.5" />
                      <span>Advanced AI analysis</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-blue-600 mr-2 flex-shrink-0 mt-0.5" />
                      <span>Mobile notifications</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-blue-600 mr-2 flex-shrink-0 mt-0.5" />
                      <span>Risk management tools</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-blue-600 mr-2 flex-shrink-0 mt-0.5" />
                      <span>Portfolio analytics</span>
                    </li>
                  </ul>
                  
                  <Button asChild className="w-full bg-blue-600 hover:bg-blue-700">
                    <Link to="/auth/signup">Get started</Link>
                  </Button>
                  <p className="text-xs text-center text-gray-500 mt-4">7-day free trial included</p>
                </CardContent>
              </Card>
              
              {/* Enterprise Card */}
              <Card className="border-gray-200 shadow-sm">
                <CardContent className="p-6">
                  <div className="bg-gray-100 text-gray-700 text-xs font-semibold px-3 py-1 rounded-full inline-block mb-4">
                    ENTERPRISE
                  </div>
                  <h3 className="text-xl font-semibold mb-2">Enterprise</h3>
                  <div className="text-4xl font-bold mb-1">$199<span className="text-base font-normal text-gray-600">/month</span></div>
                  <p className="text-sm text-gray-500 mb-6">Billed monthly or $1999/year</p>
                  
                  <ul className="space-y-3 mb-8">
                    <li className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span>Everything in Pro</span>
                    </li>
                    <li className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span>1-on-1 strategy sessions</span>
                    </li>
                    <li className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span>Custom API integration</span>
                    </li>
                    <li className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span>Priority support</span>
                    </li>
                    <li className="flex items-start">
                      <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span>White-label option</span>
                    </li>
                  </ul>
                  
                  <Button asChild className="w-full bg-blue-600 hover:bg-blue-700">
                    <Link to="/auth/signup">Contact sales</Link>
                  </Button>
                  <p className="text-xs text-center text-gray-500 mt-4">7-day free trial included</p>
                </CardContent>
              </Card>
            </div>
          </div>
        </section>
        
        <section className="py-16 bg-gray-50 border-y border-gray-200">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold text-gray-900 mb-4">Frequently asked questions</h2>
              <p className="text-xl text-gray-600 max-w-3xl mx-auto">
                Everything you need to know about our pricing and products
              </p>
            </div>
            
            <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
              <div>
                <h3 className="text-lg font-semibold mb-2">How does the 7-day free trial work?</h3>
                <p className="text-gray-600">You can sign up and use all features for 7 days without providing any payment information. After the trial period, you can choose to subscribe to one of our paid plans.</p>
              </div>
              
              <div>
                <h3 className="text-lg font-semibold mb-2">Can I cancel my subscription?</h3>
                <p className="text-gray-600">Yes, you can cancel your subscription at any time. Your access will continue until the end of your billing period.</p>
              </div>
              
              <div>
                <h3 className="text-lg font-semibold mb-2">What payment methods do you accept?</h3>
                <p className="text-gray-600">We accept all major credit cards, PayPal, and bank transfers for annual plans.</p>
              </div>
              
              <div>
                <h3 className="text-lg font-semibold mb-2">Is there a discount for annual billing?</h3>
                <p className="text-gray-600">Yes, you save approximately 15% when choosing annual billing compared to monthly billing.</p>
              </div>
            </div>
          </div>
        </section>
        
        <section className="py-16 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6">
            <div className="bg-blue-600 rounded-2xl p-8 md:p-12 text-center">
              <h2 className="text-3xl font-bold text-white mb-4">Ready to transform your trading?</h2>
              <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
                Join thousands of successful traders who have improved their results with MarketGuru
              </p>
              <Button asChild size="lg" className="bg-white text-blue-600 hover:bg-blue-50">
                <Link to="/auth/signup">Start your free trial today</Link>
              </Button>
              <p className="text-blue-200 mt-4">No credit card required. 7-day free trial.</p>
            </div>
          </div>
        </section>
      </main>
      
      <footer className="bg-white py-8 border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <p className="text-center text-gray-500">
            &copy; {new Date().getFullYear()} MarketGuru. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Pricing;
