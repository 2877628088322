
import { useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { Session, User } from '@supabase/supabase-js';
import { checkIsAdmin, checkProfileComplete } from '@/utils/authUtils';

export const useAuthEffects = (
  setSession: (session: Session | null) => void,
  setUser: (user: User | null) => void,
  setLoading: (loading: boolean) => void,
  setIsProfileComplete: (isComplete: boolean) => void,
  setIsAdmin: (isAdmin: boolean) => void
) => {
  useEffect(() => {
    console.log("Setting up auth state listener");
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (_event, currentSession) => {
        console.log("Auth state changed:", _event, currentSession?.user?.email);
        setSession(currentSession);
        setUser(currentSession?.user ?? null);
        
        if (currentSession?.user) {
          setTimeout(async () => {
            const isComplete = await checkProfileComplete(currentSession.user);
            const userIsAdmin = await checkIsAdmin(currentSession.user);
            setIsProfileComplete(isComplete);
            setIsAdmin(userIsAdmin);
          }, 0);
        }
      }
    );

    supabase.auth.getSession().then(async ({ data: { session: currentSession } }) => {
      console.log("Initial session check:", currentSession?.user?.email);
      setSession(currentSession);
      setUser(currentSession?.user ?? null);
      
      if (currentSession?.user) {
        const isComplete = await checkProfileComplete(currentSession.user);
        const userIsAdmin = await checkIsAdmin(currentSession.user);
        setIsProfileComplete(isComplete);
        setIsAdmin(userIsAdmin);
      }
      
      setLoading(false);
    });

    return () => subscription.unsubscribe();
  }, [setSession, setUser, setLoading, setIsProfileComplete, setIsAdmin]);
};
