
export type BlogPost = {
  id: string;
  title: string;
  content: string;
  summary: string;
  image_url?: string;
  published_at: string;
  updated_at?: string;
  created_at?: string;
  author: string;
  category: string;
  tags: string[];
  slug: string;
  read_time: number;
  is_published?: boolean;
  created_by?: string;
};

export const BLOG_CATEGORIES = [
  "Market Analysis",
  "Free Signals",
  "General"
];

export type RichTextEditorValue = {
  type: string;
  children: {
    type?: string;
    text?: string;
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    code?: boolean;
    url?: string;
  }[];
};
