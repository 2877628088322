
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useNavigate } from "react-router-dom";
import { ChevronDown, UserCircle, LogOut, BarChart2, Settings, Shield } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";

const TradingHeader = () => {
  const { user, signOut, isAdmin } = useAuth();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleSignOut = async () => {
    await signOut();
    navigate("/");
  };

  return (
    <header className="bg-background border-b sticky top-0 z-10" role="banner">
      <div className="max-w-7xl mx-auto p-4 flex items-center justify-between">
        <div className="flex items-center">
          <Link to="/app" className="text-xl font-bold flex items-center" aria-label="Go to Trading Platform">
            <BarChart2 className="h-6 w-6 mr-2" aria-hidden="true" />
            <span className={isMobile ? "sr-only" : ""}>TradingSignals</span>
          </Link>
        </div>

        <div className="flex items-center space-x-2 md:space-x-4">
          {isAdmin && (
            <Button 
              variant="outline" 
              className="hidden sm:flex items-center"
              onClick={() => navigate("/admin/signals")}
              aria-label="Admin Panel"
            >
              <Shield className="h-4 w-4 mr-2" aria-hidden="true" />
              <span>Admin Panel</span>
            </Button>
          )}
          
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline" className="flex items-center">
                <UserCircle className="h-4 w-4 mr-2" aria-hidden="true" />
                <span className={isMobile ? "sr-only" : ""}>Account</span>
                <ChevronDown className="h-4 w-4 ml-1" aria-hidden="true" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuLabel>
                {user?.email || "Account"}
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={() => navigate("/profile")}>
                <UserCircle className="h-4 w-4 mr-2" aria-hidden="true" />
                Profile
              </DropdownMenuItem>
              
              {isAdmin && (
                <DropdownMenuItem onClick={() => navigate("/admin/signals")}>
                  <Shield className="h-4 w-4 mr-2" aria-hidden="true" />
                  Admin Panel
                </DropdownMenuItem>
              )}
              
              <DropdownMenuSeparator />
              <DropdownMenuItem onClick={handleSignOut}>
                <LogOut className="h-4 w-4 mr-2" aria-hidden="true" />
                Sign Out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
    </header>
  );
};

export default TradingHeader;
