
import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { usePortfolio } from "@/hooks/usePortfolio";
import { Progress } from "@/components/ui/progress";
import { ShieldAlert, AlertTriangle, TrendingDown, Info } from "lucide-react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";

const RiskMetric = ({ 
  title, 
  value, 
  description, 
  color, 
  icon: Icon,
  maxValue = 100
}: { 
  title: string; 
  value: number; 
  description: string; 
  color: string;
  icon: any;
  maxValue?: number;
}) => (
  <div className="space-y-2">
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-1.5">
        <Icon className={`h-4 w-4 ${color}`} />
        <h4 className="text-sm font-medium">{title}</h4>
      </div>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <Info className="h-3.5 w-3.5 text-muted-foreground" />
          </TooltipTrigger>
          <TooltipContent>
            <p className="max-w-xs text-xs">{description}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
    <Progress value={(value / maxValue) * 100} className="h-2" 
      indicatorClassName={`${color === 'text-green-500' ? 'bg-green-500' : 
        color === 'text-amber-500' ? 'bg-amber-500' : 
        color === 'text-red-500' ? 'bg-red-500' : 'bg-blue-500'}`} 
    />
    <div className="flex items-center justify-between text-xs text-muted-foreground">
      <span>{value.toFixed(2)}</span>
      <span>Max: {maxValue}</span>
    </div>
  </div>
);

const PortfolioRiskAnalysis = () => {
  const { holdings } = usePortfolio();
  
  // Calculate portfolio concentration (based on top holding percentage)
  const calculateConcentration = () => {
    if (!holdings.length) return 0;
    
    const sortedHoldings = [...holdings]
      .sort((a, b) => (b.allocation || 0) - (a.allocation || 0));
    
    return sortedHoldings[0]?.allocation || 0;
  };
  
  // Calculate a simplified volatility score (normally would use historical data)
  // Here we're just using a placeholder calculation based on portfolio diversity
  const calculateVolatility = () => {
    if (holdings.length <= 1) return 75; // High volatility for single stock
    if (holdings.length <= 3) return 60;
    if (holdings.length <= 5) return 45;
    if (holdings.length <= 8) return 30;
    return 20; // Lower volatility for more diverse portfolio
  };
  
  // Calculate a simplified drawdown risk
  // Here we use a placeholder based on negative performers
  const calculateDrawdownRisk = () => {
    if (!holdings.length) return 0;
    
    // Count holdings with negative returns
    const negativePerformers = holdings.filter(h => (h.change || 0) < 0).length;
    const negativePercentage = (negativePerformers / holdings.length) * 100;
    
    // Scale to a 0-100 risk score
    return Math.min(Math.max(negativePercentage * 1.5, 10), 100);
  };
  
  // Calculate a simple diversification score
  const calculateDiversification = () => {
    if (holdings.length <= 1) return 10;
    if (holdings.length <= 3) return 30;
    if (holdings.length <= 5) return 50;
    if (holdings.length <= 8) return 70;
    if (holdings.length <= 12) return 85;
    return 95;
  };
  
  const concentration = calculateConcentration();
  const volatility = calculateVolatility();
  const drawdownRisk = calculateDrawdownRisk();
  const diversification = calculateDiversification();
  
  return (
    <Card>
      <CardHeader>
        <CardTitle>Risk Analysis</CardTitle>
        <CardDescription>
          Key metrics to understand your portfolio risk profile
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        {holdings.length > 0 ? (
          <>
            <RiskMetric 
              title="Concentration Risk" 
              value={concentration}
              description="Measures the percentage of your portfolio invested in your largest holding. Lower is better for diversification."
              color={
                concentration < 20 ? "text-green-500" : 
                concentration < 35 ? "text-amber-500" : "text-red-500"
              }
              icon={ShieldAlert}
            />
            
            <RiskMetric 
              title="Portfolio Volatility" 
              value={volatility}
              maxValue={100}
              description="Estimates how much your portfolio value might fluctuate based on its composition. Lower volatility means more stable returns."
              color={
                volatility < 30 ? "text-green-500" : 
                volatility < 60 ? "text-amber-500" : "text-red-500"
              }
              icon={AlertTriangle}
            />
            
            <RiskMetric 
              title="Drawdown Risk" 
              value={drawdownRisk}
              maxValue={100}
              description="Represents the potential for a significant decline in portfolio value based on current positions. Lower is better."
              color={
                drawdownRisk < 30 ? "text-green-500" : 
                drawdownRisk < 60 ? "text-amber-500" : "text-red-500"
              }
              icon={TrendingDown}
            />
            
            <RiskMetric 
              title="Diversification Score" 
              value={diversification}
              maxValue={100}
              description="Measures how well your portfolio is spread across different assets. Higher scores indicate better diversification."
              color="text-blue-500"
              icon={Info}
            />
          </>
        ) : (
          <div className="py-12 text-center text-muted-foreground">
            <p>Add positions to your portfolio to view risk analysis</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default PortfolioRiskAnalysis;
