
import { useState, useEffect, useCallback } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';
import { SignalData } from '@/components/trading/SignalCard';

export const useSignals = () => {
  const [signals, setSignals] = useState<SignalData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { toast } = useToast();

  // Function to convert Supabase trading signal to SignalData
  const convertTradingSignal = (signal: any): SignalData => {
    return {
      id: signal.id,
      symbol: signal.symbol,
      signal: signal.signal,
      entryPrice: Number(signal.entry_price),
      stopLoss: Number(signal.stop_loss),
      targetPrice: Number(signal.target_price),
      pnl: calculatePnl(signal),
      analysis: signal.analysis || "",
      currentPrice: signal.current_price ? Number(signal.current_price) : undefined,
      lastUpdated: signal.last_updated ? new Date(signal.last_updated).toLocaleTimeString() : undefined,
      createdAt: signal.created_at, // Include created_at for admin panel
      status: signal.status || 'open', // Include status
      neoScore: signal.neo_score !== null ? Number(signal.neo_score) : undefined, // Add neo_score
    };
  };

  // Calculate PnL based on signal data
  const calculatePnl = (signal: any): number => {
    const entryPrice = Number(signal.entry_price);
    const currentPrice = signal.current_price ? Number(signal.current_price) : entryPrice;
    
    if (signal.signal === "Long") {
      return Number(((currentPrice - entryPrice) / entryPrice * 100).toFixed(2));
    } else if (signal.signal === "Short") {
      return Number(((entryPrice - currentPrice) / entryPrice * 100).toFixed(2));
    }
    return 0;
  };

  // Fetch signals from Supabase
  const fetchSignals = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      // Fetch trading signals
      const { data: signalsData, error: signalsError } = await supabase
        .from('trading_signals')
        .select('*')
        .order('created_at', { ascending: false });
      
      if (signalsError) throw signalsError;
      
      if (!signalsData || signalsData.length === 0) {
        setSignals([]);
        return;
      }
      
      // Convert signals and set to state
      const convertedSignals = signalsData.map(convertTradingSignal);
      
      // For each signal, fetch media and history in parallel
      const signalsWithDetails = await Promise.all(
        convertedSignals.map(async (signal) => {
          // Fetch media for this signal
          const { data: mediaData } = await supabase
            .from('signal_media')
            .select('*')
            .eq('signal_id', signal.id);
            
          // Fetch update history for this signal
          const { data: historyData } = await supabase
            .from('signal_history')
            .select('*')
            .eq('signal_id', signal.id)
            .order('created_at', { ascending: false });
            
          return {
            ...signal,
            media: mediaData ? mediaData.map((media: any) => ({
              type: media.type as "image" | "video",
              url: media.url,
              thumbnail: media.thumbnail || undefined,
              title: media.title || undefined,
              description: media.description || undefined
            })) : [],
            updatesHistory: historyData ? historyData.map((history: any) => ({
              date: new Date(history.created_at).toLocaleDateString(),
              field: history.field,
              oldValue: history.old_value,
              newValue: history.new_value,
              reason: history.reason || undefined
            })) : []
          };
        })
      );
      
      setSignals(signalsWithDetails);
      
    } catch (error: any) {
      console.error('Error fetching signals:', error);
      setError(error.message);
      toast({
        title: "Error",
        description: "Failed to fetch trading signals",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  }, [toast]);

  // Fetch signals on component mount
  useEffect(() => {
    fetchSignals();
  }, [fetchSignals]);

  return {
    signals,
    isLoading,
    error,
    refetch: fetchSignals
  };
};
