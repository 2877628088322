import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Loader2, Plus, X, Upload, Image as ImageIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Card,
  CardContent,
} from "@/components/ui/card";
import { SignalData } from "@/components/trading/SignalCard";

// Validation schema
const formSchema = z.object({
  symbol: z.string().min(1, "Symbol is required"),
  signal: z.enum(["Long", "Short"]),
  assetClass: z.enum(["Stock", "Forex", "Crypto"]),
  entryPrice: z.string().min(1, "Entry price is required")
    .refine(val => !isNaN(parseFloat(val)), "Must be a valid number"),
  stopLoss: z.string().min(1, "Stop loss is required")
    .refine(val => !isNaN(parseFloat(val)), "Must be a valid number"),
  targetPrice: z.string().min(1, "Target price is required")
    .refine(val => !isNaN(parseFloat(val)), "Must be a valid number"),
  currentPrice: z.string()
    .refine(val => val === "" || !isNaN(parseFloat(val)), "Must be a valid number if provided")
    .optional(),
  neoScore: z.string()
    .refine(val => val === "" || (!isNaN(parseInt(val)) && parseInt(val) >= 0 && parseInt(val) <= 100), 
            "NEO Score must be a number between 0 and 100 if provided")
    .optional(),
  analysis: z.string().optional(),
});

type FormValues = z.infer<typeof formSchema>;

interface MediaItem {
  id?: string;
  type: "image" | "video";
  url: string;
  thumbnail?: string;
  title?: string;
  description?: string;
  file?: File;
  isNew?: boolean;
  isUploading?: boolean;
}

interface CreateSignalFormProps {
  signalId?: string | null;
  onSuccess?: () => void;
}

const CreateSignalForm: React.FC<CreateSignalFormProps> = ({ signalId, onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [mediaItems, setMediaItems] = useState<MediaItem[]>([]);
  const [mediaToDelete, setMediaToDelete] = useState<string[]>([]);
  const { toast } = useToast();

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      symbol: "",
      signal: "Long",
      assetClass: "Stock",
      entryPrice: "",
      stopLoss: "",
      targetPrice: "",
      currentPrice: "",
      neoScore: "",
      analysis: "",
    }
  });

  useEffect(() => {
    const fetchSignalData = async () => {
      if (!signalId) return;
      
      try {
        setIsFetching(true);
        
        const { data: signalData, error: signalError } = await supabase
          .from('trading_signals')
          .select('*')
          .eq('id', signalId)
          .single();
        
        if (signalError) throw signalError;
        
        if (signalData) {
          form.reset({
            symbol: signalData.symbol,
            signal: signalData.signal as "Long" | "Short",
            assetClass: signalData.asset_class as "Stock" | "Forex" | "Crypto",
            entryPrice: signalData.entry_price.toString(),
            stopLoss: signalData.stop_loss.toString(),
            targetPrice: signalData.target_price.toString(),
            currentPrice: signalData.current_price ? signalData.current_price.toString() : "",
            neoScore: signalData.neo_score !== null ? signalData.neo_score.toString() : "",
            analysis: signalData.analysis || "",
          });
          
          const { data: mediaData, error: mediaError } = await supabase
            .from('signal_media')
            .select('*')
            .eq('signal_id', signalId);
          
          if (mediaError) throw mediaError;
          
          if (mediaData && mediaData.length > 0) {
            setMediaItems(mediaData.map(item => ({
              id: item.id,
              type: item.type as "image" | "video",
              url: item.url,
              thumbnail: item.thumbnail || undefined,
              title: item.title || undefined,
              description: item.description || undefined,
            })));
          }
        }
      } catch (error: any) {
        console.error("Error fetching signal data:", error);
        toast({
          title: "Error",
          description: error.message || "Failed to load signal data",
          variant: "destructive",
        });
      } finally {
        setIsFetching(false);
      }
    };
    
    fetchSignalData();
  }, [signalId, form, toast]);

  const onSubmit = async (values: FormValues) => {
    try {
      setIsLoading(true);
      
      const numericValues = {
        entry_price: parseFloat(values.entryPrice),
        stop_loss: parseFloat(values.stopLoss),
        target_price: parseFloat(values.targetPrice),
        current_price: values.currentPrice ? parseFloat(values.currentPrice) : null,
        neo_score: values.neoScore && values.neoScore !== "" ? parseInt(values.neoScore) : null,
      };
      
      let signalResult;
      
      if (signalId) {
        const { data, error } = await supabase
          .from('trading_signals')
          .update({
            symbol: values.symbol,
            signal: values.signal,
            asset_class: values.assetClass,
            ...numericValues,
            analysis: values.analysis || null,
            updated_at: new Date().toISOString(),
          })
          .eq('id', signalId)
          .select();
        
        if (error) throw error;
        signalResult = data?.[0];
      } else {
        const { data, error } = await supabase
          .from('trading_signals')
          .insert({
            symbol: values.symbol,
            signal: values.signal,
            asset_class: values.assetClass,
            ...numericValues,
            analysis: values.analysis || null,
          })
          .select();
        
        if (error) throw error;
        signalResult = data?.[0];
      }
      
      if (signalResult) {
        if (mediaToDelete.length > 0) {
          await Promise.all(mediaToDelete.map(async (mediaId) => {
            const { error } = await supabase
              .from('signal_media')
              .delete()
              .eq('id', mediaId);
            
            if (error) throw error;
          }));
        }
        
        const newMediaItems = mediaItems.filter(item => item.isNew);
        
        await Promise.all(newMediaItems.map(async (item) => {
          const { error } = await supabase
            .from('signal_media')
            .insert({
              signal_id: signalResult.id,
              type: item.type,
              url: item.url,
              thumbnail: item.thumbnail || null,
              title: item.title || null,
              description: item.description || null,
            });
          
          if (error) throw error;
        }));
      }
      
      toast({
        title: signalId ? "Signal Updated" : "Signal Created",
        description: signalId 
          ? `The ${values.symbol} signal has been updated successfully.`
          : `A new ${values.symbol} signal has been created successfully.`,
      });
      
      if (!signalId) {
        form.reset();
        setMediaItems([]);
      }
      
      if (onSuccess) {
        onSuccess();
      }
    } catch (error: any) {
      console.error("Error saving signal:", error);
      toast({
        title: "Error",
        description: error.message || "Failed to save signal",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddMediaItem = () => {
    setMediaItems([
      ...mediaItems, 
      {
        type: "image",
        url: "",
        title: "",
        description: "",
        isNew: true,
      }
    ]);
  };

  const handleRemoveMediaItem = (index: number) => {
    const itemToRemove = mediaItems[index];
    
    if (itemToRemove.id) {
      setMediaToDelete([...mediaToDelete, itemToRemove.id]);
    }
    
    setMediaItems(mediaItems.filter((_, i) => i !== index));
  };

  const handleMediaChange = (index: number, field: keyof MediaItem, value: any) => {
    const updatedItems = [...mediaItems];
    updatedItems[index] = {
      ...updatedItems[index],
      [field]: value,
      isNew: updatedItems[index].isNew,
    };
    setMediaItems(updatedItems);
  };

  const handleFileUpload = async (index: number, file: File) => {
    if (!file) return;
    
    try {
      const updatedItems = [...mediaItems];
      updatedItems[index] = {
        ...updatedItems[index],
        isUploading: true,
      };
      setMediaItems(updatedItems);
      
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const fileUrl = URL.createObjectURL(file);
      
      const finalItems = [...mediaItems];
      finalItems[index] = {
        ...finalItems[index],
        url: fileUrl,
        type: file.type.startsWith('image/') ? 'image' : 'video',
        isUploading: false,
        isNew: true,
        file: file,
      };
      setMediaItems(finalItems);
    } catch (error: any) {
      console.error("Error uploading file:", error);
      toast({
        title: "Upload Failed",
        description: error.message || "Failed to upload file",
        variant: "destructive",
      });
      
      const updatedItems = [...mediaItems];
      updatedItems[index] = {
        ...updatedItems[index],
        isUploading: false,
      };
      setMediaItems(updatedItems);
    }
  };

  if (isFetching) {
    return (
      <div className="flex justify-center p-8">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="symbol"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Symbol</FormLabel>
                <FormControl>
                  <Input placeholder="AAPL" {...field} />
                </FormControl>
                <FormDescription>
                  Enter the stock/crypto/forex symbol
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          
          <FormField
            control={form.control}
            name="assetClass"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Asset Class</FormLabel>
                <Select 
                  onValueChange={field.onChange} 
                  defaultValue={field.value}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select an asset class" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="Stock">Stock</SelectItem>
                    <SelectItem value="Crypto">Cryptocurrency</SelectItem>
                    <SelectItem value="Forex">Forex</SelectItem>
                  </SelectContent>
                </Select>
                <FormDescription>
                  Select the asset class for this signal
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        
        <FormField
          control={form.control}
          name="signal"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Signal Type</FormLabel>
              <Select 
                onValueChange={field.onChange} 
                defaultValue={field.value}
                value={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select signal type" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="Long">Long (Buy)</SelectItem>
                  <SelectItem value="Short">Short (Sell)</SelectItem>
                </SelectContent>
              </Select>
              <FormDescription>
                Indicate whether this is a buy or sell signal
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          <FormField
            control={form.control}
            name="entryPrice"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Entry Price</FormLabel>
                <FormControl>
                  <Input placeholder="100.00" {...field} />
                </FormControl>
                <FormDescription>
                  Recommended entry price
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          
          <FormField
            control={form.control}
            name="stopLoss"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Stop Loss</FormLabel>
                <FormControl>
                  <Input placeholder="95.00" {...field} />
                </FormControl>
                <FormDescription>
                  Price to exit if trade goes against signal
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          
          <FormField
            control={form.control}
            name="targetPrice"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Target Price</FormLabel>
                <FormControl>
                  <Input placeholder="120.00" {...field} />
                </FormControl>
                <FormDescription>
                  Price target for taking profit
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="currentPrice"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Current Price (Optional)</FormLabel>
                <FormControl>
                  <Input placeholder="102.50" {...field} />
                </FormControl>
                <FormDescription>
                  Current market price (leave empty to use entry price)
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          
          <FormField
            control={form.control}
            name="neoScore"
            render={({ field }) => (
              <FormItem>
                <FormLabel>NEO Score (Optional)</FormLabel>
                <FormControl>
                  <Input placeholder="75" {...field} />
                </FormControl>
                <FormDescription>
                  New Entry Odds score (0-100)
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        
        <FormField
          control={form.control}
          name="analysis"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Technical Analysis</FormLabel>
              <FormControl>
                <Textarea 
                  placeholder="Provide detailed technical analysis and reasoning for this signal..." 
                  className="min-h-[150px]"
                  {...field} 
                />
              </FormControl>
              <FormDescription>
                Detailed explanation of the analysis behind this signal
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium">Media Attachments</h3>
            <Button 
              type="button" 
              variant="outline" 
              size="sm" 
              onClick={handleAddMediaItem}
            >
              <Plus className="h-4 w-4 mr-2" />
              Add Media
            </Button>
          </div>
          
          {mediaItems.length === 0 ? (
            <div className="p-8 border rounded-md text-center bg-muted/20">
              <ImageIcon className="h-8 w-8 mx-auto text-muted-foreground mb-2" />
              <p className="text-muted-foreground">
                No media attachments yet. Add charts, images or videos to support your analysis.
              </p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {mediaItems.map((item, index) => (
                <Card key={index} className="overflow-hidden">
                  <CardContent className="p-4">
                    <div className="flex justify-between items-start mb-3">
                      <h4 className="font-medium">
                        {item.type === "image" ? "Image" : "Video"} {index + 1}
                      </h4>
                      <Button
                        type="button"
                        variant="ghost"
                        size="sm"
                        className="h-8 w-8 p-0"
                        onClick={() => handleRemoveMediaItem(index)}
                      >
                        <X className="h-4 w-4" />
                        <span className="sr-only">Remove</span>
                      </Button>
                    </div>
                    
                    <div className="space-y-3">
                      {item.isUploading ? (
                        <div className="flex items-center justify-center p-4 border rounded-md h-[200px] bg-muted/20">
                          <div className="flex flex-col items-center">
                            <Loader2 className="h-6 w-6 animate-spin mb-2" />
                            <span className="text-sm text-muted-foreground">Uploading...</span>
                          </div>
                        </div>
                      ) : item.url ? (
                        <div className="relative aspect-video border rounded-md overflow-hidden bg-muted/20">
                          {item.type === "image" ? (
                            <img
                              src={item.url}
                              alt={item.title || "Analysis image"}
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <div className="w-full h-full flex items-center justify-center">
                              <span className="text-sm text-muted-foreground">Video URL: {item.url}</span>
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="flex items-center justify-center border rounded-md p-4 h-[200px] bg-muted/20">
                          <label className="cursor-pointer flex flex-col items-center">
                            <Upload className="h-8 w-8 mb-2 text-muted-foreground" />
                            <span className="text-sm font-medium mb-1">Upload {item.type}</span>
                            <span className="text-xs text-muted-foreground">Click to browse files</span>
                            <input 
                              type="file" 
                              className="hidden" 
                              accept={item.type === "image" ? "image/*" : "video/*"}
                              onChange={(e) => {
                                if (e.target.files && e.target.files[0]) {
                                  handleFileUpload(index, e.target.files[0]);
                                }
                              }}
                            />
                          </label>
                        </div>
                      )}
                      
                      <div className="space-y-2">
                        <div>
                          <label htmlFor={`media-url-${index}`} className="text-xs font-medium">
                            URL
                          </label>
                          <Input
                            id={`media-url-${index}`}
                            value={item.url}
                            onChange={(e) => handleMediaChange(index, "url", e.target.value)}
                            placeholder={`${item.type === "image" ? "Image" : "Video"} URL`}
                            className="mt-1"
                          />
                        </div>
                        
                        <div>
                          <label htmlFor={`media-title-${index}`} className="text-xs font-medium">
                            Title
                          </label>
                          <Input
                            id={`media-title-${index}`}
                            value={item.title || ""}
                            onChange={(e) => handleMediaChange(index, "title", e.target.value)}
                            placeholder="Title (optional)"
                            className="mt-1"
                          />
                        </div>
                        
                        <div>
                          <label htmlFor={`media-desc-${index}`} className="text-xs font-medium">
                            Description
                          </label>
                          <Textarea
                            id={`media-desc-${index}`}
                            value={item.description || ""}
                            onChange={(e) => handleMediaChange(index, "description", e.target.value)}
                            placeholder="Description (optional)"
                            className="mt-1 min-h-[60px]"
                          />
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ))}
            </div>
          )}
        </div>
        
        <div className="flex justify-end space-x-2">
          <Button
            type="button"
            variant="outline"
            onClick={() => {
              form.reset();
              setMediaItems([]);
              if (onSuccess) onSuccess();
            }}
          >
            Cancel
          </Button>
          <Button type="submit" disabled={isLoading}>
            {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
            {signalId ? "Update Signal" : "Create Signal"}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default CreateSignalForm;
