
import React from "react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Zap, ChevronRight } from "lucide-react";

export const AIInsightCard = () => {
  return (
    <Card className="mt-4">
      <CardContent className="p-3 sm:p-4">
        <div className="text-xs sm:text-sm font-medium mb-2">AI Analysis</div>
        <div className="p-2 sm:p-3 bg-blue-50 rounded-lg border border-blue-100 mb-3">
          <div className="flex items-start">
            <Zap className="h-4 w-4 sm:h-5 sm:w-5 text-blue-600 mt-0.5 mr-2 flex-shrink-0" />
            <p className="text-xs sm:text-sm text-gray-800">
              <span className="font-semibold">Market Insight:</span> Bullish momentum detected in tech sector with strong support levels. Consider diversifying into semiconductor stocks.
            </p>
          </div>
        </div>
        
        <div className="flex flex-row justify-between items-center">
          <Button variant="outline" size="sm" className="text-[10px] sm:text-xs h-7 sm:h-8">
            View More Insights
            <ChevronRight className="ml-1 h-2 w-2 sm:h-3 sm:w-3" />
          </Button>
          
          <Badge className="bg-green-600 text-[10px] sm:text-xs">New Insights: 3</Badge>
        </div>
      </CardContent>
    </Card>
  );
};
