
import { useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

interface MarketPrice {
  symbol: string;
  current_price: number;
  daily_change_percent?: number;
  daily_high?: number;
  daily_low?: number;
  volume?: number;
  last_updated?: string;
}

interface MarketPricesResult {
  prices: Record<string, MarketPrice>;
  lastUpdated: string | null;
  isLoading: boolean;
  refreshPrices: (symbols?: string[]) => Promise<void>;
  getPrice: (symbol: string) => MarketPrice | null;
  getPriceForSymbols: (symbols: string[]) => Promise<void>;
}

export function useMarketPrices(): MarketPricesResult {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);
  
  // Fetch market prices from our database
  const { data: prices = {}, isLoading } = useQuery({
    queryKey: ['market-prices'],
    queryFn: async () => {
      // Get all market prices from our database
      const { data, error } = await supabase
        .from('market_prices')
        .select('*');
        
      if (error) {
        console.error('Error fetching market prices:', error);
        throw error;
      }
      
      // Convert the array to a symbol-indexed object for easy lookup
      const priceMap: Record<string, MarketPrice> = {};
      for (const price of data) {
        priceMap[price.symbol] = {
          symbol: price.symbol,
          current_price: typeof price.current_price === 'string' ? parseFloat(price.current_price) : price.current_price,
          daily_change_percent: price.daily_change_percent !== null ? (typeof price.daily_change_percent === 'string' ? parseFloat(price.daily_change_percent) : price.daily_change_percent) : undefined,
          daily_high: price.daily_high !== null ? (typeof price.daily_high === 'string' ? parseFloat(price.daily_high) : price.daily_high) : undefined,
          daily_low: price.daily_low !== null ? (typeof price.daily_low === 'string' ? parseFloat(price.daily_low) : price.daily_low) : undefined,
          volume: price.volume,
          last_updated: price.last_updated
        };
      }
      
      // Set the last updated time
      if (data.length > 0) {
        const timestamps = data
          .filter(p => p.last_updated)
          .map(p => new Date(p.last_updated).getTime());
        
        if (timestamps.length > 0) {
          const latestTimestamp = Math.max(...timestamps);
          setLastUpdated(new Date(latestTimestamp).toLocaleTimeString());
        }
      }
      
      return priceMap;
    },
    staleTime: 60 * 1000, // 1 minute
  });
  
  // Track new symbols (add them to tracked_symbols table)
  const trackSymbols = async (symbols: string[]) => {
    if (!symbols.length) return;
    
    try {
      // First, try to ensure all symbols exist in market_prices
      for (const symbol of symbols) {
        await supabase
          .from('market_prices')
          .upsert({ 
            symbol, 
            current_price: 0,  // Using numeric value directly
            last_updated: new Date().toISOString()
          }, { 
            onConflict: 'symbol' 
          });
      }
      
      // Then add them to tracked_symbols
      for (const symbol of symbols) {
        await supabase
          .from('tracked_symbols')
          .upsert({ symbol }, { onConflict: 'symbol' });
      }
      
      console.log(`Added ${symbols.length} symbols to tracking`);
    } catch (error) {
      console.error('Error tracking symbols:', error);
    }
  };
  
  // Get prices for specific symbols
  const getPriceForSymbols = async (symbols: string[]) => {
    if (!symbols.length) return;
    
    try {
      // Track the symbols first
      await trackSymbols(symbols);
      
      // Call our edge function to fetch latest prices for these specific symbols
      const { data, error } = await supabase.functions.invoke('fetch-market-prices', {
        body: { symbols }
      });
      
      if (error) {
        console.error('Error fetching prices for symbols:', error);
        return;
      }
      
      if (data && data.updated > 0) {
        // Invalidate the cache to trigger a refetch
        queryClient.invalidateQueries({ queryKey: ['market-prices'] });
        setLastUpdated(new Date().toLocaleTimeString());
      }
      
      console.log(`Updated prices for ${data?.updated || 0} symbols`);
    } catch (error) {
      console.error('Error fetching prices for symbols:', error);
    }
  };
  
  // Refresh prices for specified symbols or all tracked symbols
  const refreshPrices = async (symbols?: string[]) => {
    try {
      // If specific symbols are provided, make sure they're tracked
      if (symbols && symbols.length > 0) {
        await trackSymbols(symbols);
      }
      
      // Call our edge function to fetch latest prices
      const { data, error } = await supabase.functions.invoke('fetch-market-prices');
      
      if (error) {
        console.error('Error refreshing prices:', error);
        toast({
          title: "Error",
          description: "Failed to refresh market prices. Service may be unavailable.",
          variant: "destructive",
        });
        return;
      }
      
      console.log(`Updated prices for ${data?.updated || 0} symbols`);
      
      // Successfully updated prices
      if (data && data.updated > 0) {
        toast({
          title: "Prices Updated",
          description: `Updated prices for ${data.updated} symbols`,
        });
        
        // Update the last updated time
        setLastUpdated(new Date().toLocaleTimeString());
        
        // Invalidate the cache to trigger a refetch
        queryClient.invalidateQueries({ queryKey: ['market-prices'] });
      } else {
        toast({
          title: "No Updates",
          description: "No price updates were needed",
        });
      }
    } catch (error) {
      console.error('Error refreshing prices:', error);
      // Show a more user-friendly error message
      toast({
        title: "Error",
        description: "Failed to refresh market prices. Please try again later.",
        variant: "destructive",
      });
    }
  };
  
  // Helper function to get price for a specific symbol
  const getPrice = (symbol: string): MarketPrice | null => {
    return prices[symbol] || null;
  };
  
  return {
    prices,
    lastUpdated,
    isLoading,
    refreshPrices,
    getPrice,
    getPriceForSymbols
  };
}
