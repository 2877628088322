
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "@/hooks/use-toast";
import { useAuth } from "@/contexts/AuthContext";
import { BlogPost } from "@/types/blog";

export const useBlogs = () => {
  const queryClient = useQueryClient();
  const { isAdmin } = useAuth();

  // Fetch all blogs (published for regular users, all for admins)
  const getBlogs = async () => {
    let query = supabase
      .from("blog_posts")
      .select("*")
      .order("published_at", { ascending: false });

    if (!isAdmin) {
      query = query.eq("is_published", true);
    }

    const { data, error } = await query;
    
    if (error) {
      console.error("Error fetching blogs", error);
      throw error;
    }

    return data as BlogPost[];
  };

  // Get blog post by slug
  const getBlogBySlug = async (slug: string) => {
    const query = supabase
      .from("blog_posts")
      .select("*")
      .eq("slug", slug)
      .single();

    const { data, error } = await query;
    
    if (error) {
      if (error.code === 'PGRST116') {
        // Blog not found
        return null;
      }
      console.error("Error fetching blog", error);
      throw error;
    }

    return data as BlogPost;
  };

  // Create new blog post
  const createBlog = async (blog: Omit<BlogPost, "id" | "created_at" | "updated_at" | "is_published" | "created_by">) => {
    const { data, error } = await supabase
      .from("blog_posts")
      .insert({
        ...blog,
        is_published: false,
      })
      .select();

    if (error) {
      console.error("Error creating blog", error);
      throw error;
    }

    return data[0] as BlogPost;
  };

  // Update blog post
  const updateBlog = async ({ id, ...blog }: Partial<BlogPost> & { id: string }) => {
    const { data, error } = await supabase
      .from("blog_posts")
      .update({
        ...blog,
        updated_at: new Date().toISOString(),
      })
      .eq("id", id)
      .select();

    if (error) {
      console.error("Error updating blog", error);
      throw error;
    }

    return data[0] as BlogPost;
  };

  // Delete blog post
  const deleteBlog = async (id: string) => {
    const { error } = await supabase
      .from("blog_posts")
      .delete()
      .eq("id", id);

    if (error) {
      console.error("Error deleting blog", error);
      throw error;
    }
  };

  // Publish blog post
  const publishBlog = async (id: string) => {
    const { data, error } = await supabase
      .from("blog_posts")
      .update({
        is_published: true,
        published_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      })
      .eq("id", id)
      .select();

    if (error) {
      console.error("Error publishing blog", error);
      throw error;
    }

    return data[0] as BlogPost;
  };

  // Unpublish blog post
  const unpublishBlog = async (id: string) => {
    const { data, error } = await supabase
      .from("blog_posts")
      .update({
        is_published: false,
        updated_at: new Date().toISOString(),
      })
      .eq("id", id)
      .select();

    if (error) {
      console.error("Error unpublishing blog", error);
      throw error;
    }

    return data[0] as BlogPost;
  };

  // Filter blogs by category
  const getFilteredBlogs = async (category: string) => {
    let query = supabase
      .from("blog_posts")
      .select("*")
      .eq("category", category)
      .order("published_at", { ascending: false });

    if (!isAdmin) {
      query = query.eq("is_published", true);
    }

    const { data, error } = await query;
    
    if (error) {
      console.error("Error fetching filtered blogs", error);
      throw error;
    }

    return data as BlogPost[];
  };

  // Filter blogs by tag
  const getBlogsByTag = async (tag: string) => {
    let query = supabase
      .from("blog_posts")
      .select("*")
      .contains("tags", [tag])
      .order("published_at", { ascending: false });

    if (!isAdmin) {
      query = query.eq("is_published", true);
    }

    const { data, error } = await query;
    
    if (error) {
      console.error("Error fetching blogs by tag", error);
      throw error;
    }

    return data as BlogPost[];
  };

  // React Query hooks
  const useBlogsQuery = (options = {}) => useQuery({
    queryKey: ["blogs"],
    queryFn: getBlogs,
    ...options,
  });

  const useBlogQuery = (slug: string, options = {}) => useQuery({
    queryKey: ["blog", slug],
    queryFn: () => getBlogBySlug(slug),
    enabled: !!slug,
    ...options,
  });

  const useFilteredBlogsQuery = (category: string, options = {}) => useQuery({
    queryKey: ["blogs", "category", category],
    queryFn: () => getFilteredBlogs(category),
    enabled: !!category,
    ...options,
  });

  const useBlogsByTagQuery = (tag: string, options = {}) => useQuery({
    queryKey: ["blogs", "tag", tag],
    queryFn: () => getBlogsByTag(tag),
    enabled: !!tag,
    ...options,
  });

  const useCreateBlogMutation = () => useMutation({
    mutationFn: createBlog,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["blogs"] });
      toast({
        title: "Blog created",
        description: "Your blog post has been created successfully.",
      });
    },
    onError: (error) => {
      toast({
        title: "Error creating blog",
        description: `${error}`,
        variant: "destructive",
      });
    },
  });

  const useUpdateBlogMutation = () => useMutation({
    mutationFn: updateBlog,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["blogs"] });
      queryClient.invalidateQueries({ queryKey: ["blog", data.slug] });
      toast({
        title: "Blog updated",
        description: "Your blog post has been updated successfully.",
      });
    },
    onError: (error) => {
      toast({
        title: "Error updating blog",
        description: `${error}`,
        variant: "destructive",
      });
    },
  });

  const useDeleteBlogMutation = () => useMutation({
    mutationFn: deleteBlog,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["blogs"] });
      toast({
        title: "Blog deleted",
        description: "Your blog post has been deleted successfully.",
      });
    },
    onError: (error) => {
      toast({
        title: "Error deleting blog",
        description: `${error}`,
        variant: "destructive",
      });
    },
  });

  const usePublishBlogMutation = () => useMutation({
    mutationFn: publishBlog,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["blogs"] });
      queryClient.invalidateQueries({ queryKey: ["blog", data.slug] });
      toast({
        title: "Blog published",
        description: "Your blog post has been published successfully.",
      });
    },
    onError: (error) => {
      toast({
        title: "Error publishing blog",
        description: `${error}`,
        variant: "destructive",
      });
    },
  });

  const useUnpublishBlogMutation = () => useMutation({
    mutationFn: unpublishBlog,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["blogs"] });
      queryClient.invalidateQueries({ queryKey: ["blog", data.slug] });
      toast({
        title: "Blog unpublished",
        description: "Your blog post has been unpublished successfully.",
      });
    },
    onError: (error) => {
      toast({
        title: "Error unpublishing blog",
        description: `${error}`,
        variant: "destructive",
      });
    },
  });

  return {
    useBlogsQuery,
    useBlogQuery,
    useFilteredBlogsQuery,
    useBlogsByTagQuery,
    useCreateBlogMutation,
    useUpdateBlogMutation,
    useDeleteBlogMutation,
    usePublishBlogMutation,
    useUnpublishBlogMutation,
  };
};
