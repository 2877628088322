
import React, { useState } from "react";
import { 
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { 
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { Info, TrendingUp, TrendingDown, History, ChevronDown, Clock, Image, Video, X, Maximize2 } from "lucide-react";

interface AnalysisMedia {
  type: "image" | "video";
  url: string;
  thumbnail?: string;
  title?: string;
  description?: string;
}

interface UpdateHistory {
  date: string;
  field: string;
  oldValue: string;
  newValue: string;
  reason?: string;
}

export interface SignalData {
  id: string;
  symbol: string;
  signal: "Long" | "Short" | "Neutral";
  entryPrice: number;
  stopLoss: number;
  targetPrice: number;
  pnl: number;
  analysis: string;
  updatesHistory?: UpdateHistory[];
  currentPrice?: number;
  lastUpdated?: string;
  media?: AnalysisMedia[];
  createdAt?: string; // Added for admin panel to show creation date
  status?: 'open' | 'closed' | 'target_hit' | 'stop_loss_hit'; // Add status field
  neoScore?: number; // Add NEO Score field
}

interface SignalCardProps {
  signal: SignalData;
}

const SignalCard = ({ signal }: SignalCardProps) => {
  const [selectedMedia, setSelectedMedia] = useState<AnalysisMedia | null>(null);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  // Calculate actual PnL based on current price if available
  const calculateActualPnl = () => {
    if (!signal.currentPrice) return signal.pnl;
    
    if (signal.signal === "Long") {
      return Number(((signal.currentPrice - signal.entryPrice) / signal.entryPrice * 100).toFixed(2));
    } else if (signal.signal === "Short") {
      return Number(((signal.entryPrice - signal.currentPrice) / signal.entryPrice * 100).toFixed(2));
    }
    return 0;
  };

  const actualPnl = calculateActualPnl();

  const openMediaViewer = (media: AnalysisMedia) => {
    setSelectedMedia(media);
    setIsGalleryOpen(true);
  };

  return (
    <tr key={signal.id}>
      <td className="font-medium p-4">{signal.symbol}</td>
      <td className="p-4">
        <div className={`flex items-center gap-1 ${signal.signal === "Long" ? "text-green-600" : "text-red-600"}`}>
          {signal.signal === "Long" ? <TrendingUp className="h-4 w-4" /> : <TrendingDown className="h-4 w-4" />}
          {signal.signal}
        </div>
      </td>
      <td className="hidden md:table-cell p-4">${signal.entryPrice.toFixed(2)}</td>
      <td className="hidden md:table-cell p-4">${signal.stopLoss.toFixed(2)}</td>
      <td className="hidden md:table-cell p-4">${signal.targetPrice.toFixed(2)}</td>
      <td className="hidden md:table-cell p-4">
        {signal.currentPrice ? (
          <div className="flex items-center gap-1">
            <span>${signal.currentPrice.toFixed(2)}</span>
            {signal.lastUpdated && (
              <span className="text-xs text-muted-foreground ml-1">
                ({signal.lastUpdated})
              </span>
            )}
          </div>
        ) : (
          "-"
        )}
      </td>
      <td className={`p-4 ${actualPnl > 0 ? "text-green-600" : "text-red-600"}`}>
        {actualPnl > 0 ? "+" : ""}{actualPnl}%
      </td>
      <td className="text-right p-4">
        <Dialog>
          <DialogTrigger asChild>
            <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
              <Info className="h-4 w-4" />
              <span className="sr-only">Analysis</span>
            </Button>
          </DialogTrigger>
          <DialogContent className="sm:max-w-[600px] max-h-[90vh] overflow-y-auto">
            <DialogHeader>
              <DialogTitle>{signal.symbol} Analysis</DialogTitle>
              <DialogDescription>
                Signal Type: <span className={`font-medium ${signal.signal === "Long" ? "text-green-600" : "text-red-600"}`}>{signal.signal}</span>
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <p className="text-sm font-medium mb-1">Entry Price</p>
                  <p className="text-lg font-bold">${signal.entryPrice.toFixed(2)}</p>
                </div>
                <div>
                  <p className="text-sm font-medium mb-1">Target Price</p>
                  <p className="text-lg font-bold">${signal.targetPrice.toFixed(2)}</p>
                </div>
                <div>
                  <p className="text-sm font-medium mb-1">Stop Loss</p>
                  <p className="text-lg font-bold">${signal.stopLoss.toFixed(2)}</p>
                </div>
                <div>
                  <p className="text-sm font-medium mb-1">Current P&L</p>
                  <p className={`text-lg font-bold ${actualPnl > 0 ? "text-green-600" : "text-red-600"}`}>
                    {actualPnl > 0 ? "+" : ""}{actualPnl}%
                  </p>
                </div>
              </div>
              
              {signal.neoScore !== undefined && (
                <div className="border rounded-md p-4 bg-blue-50 dark:bg-blue-950/30">
                  <h4 className="text-sm font-medium mb-1">NEO Score</h4>
                  <p className="text-lg font-bold">{signal.neoScore}</p>
                  <p className="text-xs text-muted-foreground mt-1">New Entry Odds Score - Proprietary metric</p>
                </div>
              )}
              
              {signal.currentPrice && (
                <div className="border rounded-md p-4 bg-muted/30">
                  <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
                    <div>
                      <h4 className="text-sm font-medium">Current Price</h4>
                      <p className="text-2xl font-bold">${signal.currentPrice.toFixed(2)}</p>
                    </div>
                    {signal.lastUpdated && (
                      <div className="text-xs text-muted-foreground flex items-center">
                        <Clock className="h-3 w-3 mr-1" />
                        Last updated: {signal.lastUpdated}
                      </div>
                    )}
                  </div>
                </div>
              )}
              
              <div className="border rounded-md p-4 bg-muted/50">
                <h4 className="text-sm font-medium mb-2">Technical Analysis</h4>
                <p className="text-sm">{signal.analysis}</p>
              </div>

              {/* Analysis Media Gallery */}
              <div className="border rounded-md p-4">
                <h4 className="text-sm font-medium mb-3">Analysis Media</h4>
                {signal.media && signal.media.length > 0 ? (
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
                    {signal.media.map((item, index) => (
                      <div 
                        key={index} 
                        className="relative border rounded-md overflow-hidden cursor-pointer hover:opacity-90 transition-opacity"
                        onClick={() => openMediaViewer(item)}
                      >
                        <div className="relative aspect-square">
                          <img 
                            src={item.thumbnail || item.url} 
                            alt={item.title || `Analysis ${index + 1}`}
                            className="object-cover w-full h-full"
                          />
                          <div className="absolute top-2 right-2 bg-background/70 p-1 rounded-full">
                            {item.type === "image" ? (
                              <Image className="h-3 w-3" />
                            ) : (
                              <Video className="h-3 w-3" />
                            )}
                          </div>
                        </div>
                        {item.title && (
                          <div className="absolute bottom-0 left-0 right-0 bg-background/70 p-1 text-xs truncate">
                            {item.title}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="h-[200px] bg-muted/30 flex flex-col items-center justify-center text-muted-foreground rounded-md">
                    <Image className="h-6 w-6 mb-2 opacity-50" />
                    <p className="text-sm">No analysis media available</p>
                  </div>
                )}
              </div>
              
              {signal.updatesHistory && signal.updatesHistory.length > 0 && (
                <div className="border rounded-md p-4">
                  <Collapsible className="w-full">
                    <CollapsibleTrigger className="flex items-center justify-between w-full text-sm font-medium">
                      <div className="flex items-center gap-2">
                        <History className="h-4 w-4" />
                        <span>Updates History ({signal.updatesHistory.length})</span>
                      </div>
                      <ChevronDown className="h-4 w-4 transition-transform duration-200" />
                    </CollapsibleTrigger>
                    <CollapsibleContent className="mt-2 space-y-3 pt-3">
                      {signal.updatesHistory.map((update, index) => (
                        <div key={index} className="bg-muted/40 p-3 rounded-md text-sm">
                          <div className="flex items-center gap-2 mb-1 text-muted-foreground">
                            <Clock className="h-3 w-3" />
                            <span>{update.date}</span>
                          </div>
                          <p className="font-medium">{update.field} Updated</p>
                          <div className="grid grid-cols-2 gap-2 mt-1">
                            <div>
                              <p className="text-xs text-muted-foreground">From:</p>
                              <p className="text-xs">{update.oldValue}</p>
                            </div>
                            <div>
                              <p className="text-xs text-muted-foreground">To:</p>
                              <p className="text-xs">{update.newValue}</p>
                            </div>
                          </div>
                          {update.reason && (
                            <div className="mt-2 pt-2 border-t border-border">
                              <p className="text-xs text-muted-foreground">Reason:</p>
                              <p className="text-xs">{update.reason}</p>
                            </div>
                          )}
                        </div>
                      ))}
                    </CollapsibleContent>
                  </Collapsible>
                </div>
              )}
            </div>
            <div className="flex justify-end gap-2">
              <Button className="w-full">Add to Watchlist</Button>
            </div>
          </DialogContent>
        </Dialog>

        {/* Full-screen Media Viewer */}
        <Sheet open={isGalleryOpen} onOpenChange={setIsGalleryOpen}>
          <SheetContent side="bottom" className="h-[95vh] p-0 sm:max-w-full">
            <div className="relative h-full flex flex-col">
              <SheetHeader className="absolute top-0 left-0 right-0 z-10 bg-background/80 backdrop-blur-sm p-4">
                <div className="flex items-center justify-between">
                  <SheetTitle>{selectedMedia?.title || 'Analysis Media'}</SheetTitle>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => setIsGalleryOpen(false)}
                    className="h-8 w-8"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              </SheetHeader>
              
              <div className="flex-1 flex items-center justify-center p-4 mt-14">
                {selectedMedia?.type === "image" ? (
                  <div className="relative max-h-[80vh] flex items-center">
                    <img
                      src={selectedMedia.url}
                      alt={selectedMedia.title || "Analysis image"}
                      className="max-h-full max-w-full object-contain"
                    />
                  </div>
                ) : selectedMedia?.type === "video" && selectedMedia.url.includes("youtube") ? (
                  <div className="w-full max-w-3xl aspect-video">
                    <iframe
                      src={selectedMedia.url.replace("youtube.com/watch?v=", "youtube.com/embed/")}
                      title={selectedMedia.title || "Analysis video"}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      className="w-full h-full border-0 rounded-md"
                    ></iframe>
                  </div>
                ) : (
                  <div className="w-full max-w-3xl aspect-video">
                    <video
                      src={selectedMedia?.url}
                      controls
                      className="w-full h-full border-0 rounded-md"
                    ></video>
                  </div>
                )}
              </div>
              
              {selectedMedia?.description && (
                <div className="p-4 bg-background/80 backdrop-blur-sm">
                  <p className="text-sm">{selectedMedia.description}</p>
                </div>
              )}
            </div>
          </SheetContent>
        </Sheet>
      </td>
    </tr>
  );
};

export default SignalCard;
