
import { useState } from 'react';
import { Session, User } from '@supabase/supabase-js';
import { supabase } from '@/integrations/supabase/client';

export const useAuthState = () => {
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [isProfileComplete, setIsProfileComplete] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  return {
    session,
    setSession,
    user,
    setUser,
    loading,
    setLoading,
    isProfileComplete,
    setIsProfileComplete,
    isAdmin,
    setIsAdmin
  };
};
