
export interface TradingSignal {
  id: number;
  symbol: string;
  signal: "Buy" | "Sell";
  entryPrice: number;
  stopLoss: number;
  targetPrice: number;
  pnl: number;
  aiScore: number;
}

export const demoSignals: TradingSignal[] = [
  {
    id: 1,
    symbol: "AAPL",
    signal: "Buy",
    entryPrice: 174.82,
    stopLoss: 170.25,
    targetPrice: 188.50,
    pnl: 1.24,
    aiScore: 87,
  },
  {
    id: 2,
    symbol: "TSLA",
    signal: "Sell",
    entryPrice: 243.10,
    stopLoss: 247.80,
    targetPrice: 228.20,
    pnl: -0.68,
    aiScore: 79,
  },
  {
    id: 3,
    symbol: "NVDA",
    signal: "Buy",
    entryPrice: 856.30,
    stopLoss: 840.15,
    targetPrice: 895.00,
    pnl: 2.42,
    aiScore: 92,
  },
  {
    id: 4,
    symbol: "MSFT",
    signal: "Buy",
    entryPrice: 417.45,
    stopLoss: 410.20,
    targetPrice: 435.80,
    pnl: 0.85,
    aiScore: 84,
  },
  {
    id: 5,
    symbol: "AMZN",
    signal: "Sell",
    entryPrice: 186.70,
    stopLoss: 190.20,
    targetPrice: 175.40,
    pnl: -1.15,
    aiScore: 76,
  }
];
