
import React, { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { useQuickUpdates } from "@/hooks/useQuickUpdates";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from "@/components/ui/table";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetDescription } from "@/components/ui/sheet";
import { PlusCircle, Trash, Loader2 } from "lucide-react";
import CreateQuickUpdateForm from "./CreateQuickUpdateForm";

const QuickUpdatesManagement: React.FC = () => {
  const { updates, isLoading, refetch } = useQuickUpdates();
  const [isCreating, setIsCreating] = useState(false);
  const [updateToDelete, setUpdateToDelete] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { toast } = useToast();

  const confirmDelete = async () => {
    if (!updateToDelete) return;
    
    setIsDeleting(true);
    try {
      console.log("Deleting update with ID:", updateToDelete);
      const { error } = await supabase
        .from('quick_updates')
        .delete()
        .eq('id', updateToDelete);
      
      if (error) throw error;
      
      toast({
        title: "Update deleted",
        description: "The quick update has been removed",
      });
      
      // Important: Reset the updateToDelete state
      setUpdateToDelete(null);
      
      // Manually refresh the updates since the realtime might not be immediate
      await refetch();
    } catch (error: any) {
      console.error("Error deleting update:", error);
      toast({
        title: "Error",
        description: error.message || "Failed to delete the update",
        variant: "destructive",
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCreateSuccess = () => {
    setIsCreating(false);
    refetch();
  };

  return (
    <>
      <Card>
        <CardHeader className="flex flex-row items-start justify-between space-y-0">
          <div>
            <CardTitle>Quick Updates</CardTitle>
            <CardDescription>
              Manage quick updates that appear on the trading platform
            </CardDescription>
          </div>
          <Button onClick={() => setIsCreating(true)}>
            <PlusCircle className="mr-2 h-4 w-4" />
            New Update
          </Button>
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="flex justify-center p-8">
              <Loader2 className="h-12 w-12 animate-spin text-primary" />
            </div>
          ) : updates.length === 0 ? (
            <div className="text-center p-8 border rounded-md bg-muted/20">
              <p className="text-muted-foreground">No quick updates found.</p>
              <Button 
                variant="outline" 
                size="sm" 
                onClick={() => setIsCreating(true)} 
                className="mt-4"
              >
                <PlusCircle className="h-4 w-4 mr-2" />
                Create Your First Update
              </Button>
            </div>
          ) : (
            <div className="border rounded-md overflow-hidden">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Title</TableHead>
                    <TableHead className="hidden md:table-cell">Content</TableHead>
                    <TableHead className="hidden md:table-cell">Created</TableHead>
                    <TableHead>Actions</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {updates.map((update) => (
                    <TableRow key={update.id}>
                      <TableCell className="font-medium">{update.title}</TableCell>
                      <TableCell className="hidden md:table-cell">
                        <div className="max-w-md truncate">{update.content}</div>
                      </TableCell>
                      <TableCell className="hidden md:table-cell">
                        {new Date(update.created_at).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        <Button 
                          variant="ghost" 
                          size="sm" 
                          onClick={() => setUpdateToDelete(update.id)}
                          className="text-destructive hover:text-destructive/90 hover:bg-destructive/10"
                        >
                          <Trash className="h-4 w-4" />
                          <span className="sr-only">Delete</span>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </CardContent>
      </Card>

      {/* Sheet for creating a new update */}
      <Sheet open={isCreating} onOpenChange={setIsCreating}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Create Quick Update</SheetTitle>
            <SheetDescription>
              Add a new quick update that will be visible to all users.
            </SheetDescription>
          </SheetHeader>
          <div className="mt-6">
            <CreateQuickUpdateForm 
              onSuccess={handleCreateSuccess} 
              onCancel={() => setIsCreating(false)} 
            />
          </div>
        </SheetContent>
      </Sheet>

      {/* Alert dialog for delete confirmation */}
      <AlertDialog open={!!updateToDelete} onOpenChange={(open) => !open && setUpdateToDelete(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Delete Quick Update</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. The update will be permanently deleted from the system.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel disabled={isDeleting}>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={(e) => {
                e.preventDefault();
                confirmDelete();
              }}
              disabled={isDeleting}
              className="bg-destructive hover:bg-destructive/90"
            >
              {isDeleting ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Deleting...
                </>
              ) : (
                "Delete"
              )}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default QuickUpdatesManagement;
