
import React, { createContext, useContext, ReactNode } from 'react';
import { Session, User } from '@supabase/supabase-js';
import { NavigateFunction } from 'react-router-dom';
import { useAuthState } from '@/hooks/useAuthState';
import { useAuthEffects } from '@/hooks/useAuthEffects';
import { signIn, signOut, signUp } from '@/actions/authActions';
import { checkIsAdmin, checkProfileComplete, updateProfile } from '@/utils/authUtils';

type AuthContextType = {
  session: Session | null;
  user: User | null;
  loading: boolean;
  isProfileComplete: boolean;
  isAdmin: boolean;
  checkProfileComplete: () => Promise<boolean>;
  checkIsAdmin: () => Promise<boolean>;
  signUp: (email: string, password: string, userData: any) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  updateProfile: (data: any) => Promise<void>;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Modified AuthProviderInner to accept navigate as a prop instead of using useNavigate hook
const AuthProviderInner = ({ 
  children, 
  navigate 
}: { 
  children: ReactNode;
  navigate: NavigateFunction | null;
}) => {
  const {
    session,
    setSession,
    user,
    setUser,
    loading,
    setLoading,
    isProfileComplete,
    setIsProfileComplete,
    isAdmin,
    setIsAdmin
  } = useAuthState();

  // Set up authentication effect hooks
  useAuthEffects(
    setSession,
    setUser,
    setLoading,
    setIsProfileComplete,
    setIsAdmin
  );

  // Wrap utility functions to be used in the context
  const handleCheckProfileComplete = async (): Promise<boolean> => {
    const isComplete = await checkProfileComplete(user);
    setIsProfileComplete(isComplete);
    return isComplete;
  };

  const handleCheckIsAdmin = async (): Promise<boolean> => {
    const userIsAdmin = await checkIsAdmin(user);
    setIsAdmin(userIsAdmin);
    return userIsAdmin;
  };

  const handleSignUp = async (email: string, password: string, userData: any) => {
    // Use the imported signUp function, but only navigate if navigate is available
    if (navigate) {
      await signUp(email, password, userData, navigate);
    } else {
      // If navigate isn't available, just do the signUp without navigation
      try {
        console.log("Starting signup process without navigation");
        await signUp(email, password, userData, {} as NavigateFunction);
      } catch (error) {
        console.error('Error in signup without navigation:', error);
        throw error;
      }
    }
  };

  const handleSignIn = async (email: string, password: string) => {
    // Similar approach for sign in
    if (navigate) {
      await signIn(email, password, navigate, setIsProfileComplete, setIsAdmin);
    } else {
      try {
        console.log("Starting signin process without navigation");
        // We pass an empty object that conforms to NavigateFunction interface to avoid errors
        await signIn(email, password, {} as NavigateFunction, setIsProfileComplete, setIsAdmin);
      } catch (error) {
        console.error('Error in signin without navigation:', error);
        throw error;
      }
    }
  };

  const handleSignOut = async () => {
    if (navigate) {
      await signOut(navigate);
    } else {
      try {
        console.log("Starting signout process without navigation");
        await signOut({} as NavigateFunction);
      } catch (error) {
        console.error('Error in signout without navigation:', error);
      }
    }
  };

  const handleUpdateProfile = async (data: any) => {
    await updateProfile(user, data);
    await handleCheckProfileComplete();
  };

  return (
    <AuthContext.Provider
      value={{
        session,
        user,
        loading,
        isProfileComplete,
        isAdmin,
        checkProfileComplete: handleCheckProfileComplete,
        checkIsAdmin: handleCheckIsAdmin,
        signUp: handleSignUp,
        signIn: handleSignIn,
        signOut: handleSignOut,
        updateProfile: handleUpdateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Create a wrapper AuthProvider that doesn't use hooks
export const AuthProvider = ({ children }: { children: ReactNode }) => {
  // The wrapper doesn't try to use useNavigate() directly
  // Instead it passes null as navigate to AuthProviderInner
  // The actual navigation will happen in the components that have Router context
  return <AuthProviderInner navigate={null}>{children}</AuthProviderInner>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
