import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { Button } from "@/components/ui/button";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { toast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";

const ProfileSetup = () => {
  const { user, updateProfile, checkProfileComplete } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [tradingExperience, setTradingExperience] = useState("");
  const [selectedTier, setSelectedTier] = useState("");

  useEffect(() => {
    const fetchExistingProfile = async () => {
      if (!user) return;
      
      try {
        console.log("Fetching existing profile data");
        const { data, error } = await supabase
          .from('profiles')
          .select('date_of_birth, trading_experience, selected_tier')
          .eq('id', user.id)
          .single();
          
        if (error) {
          console.error("Error fetching profile:", error);
          return;
        }
        
        console.log("Existing profile data:", data);
        
        // Fill form with existing data if available
        if (data.date_of_birth) setDateOfBirth(data.date_of_birth);
        if (data.trading_experience) setTradingExperience(data.trading_experience);
        if (data.selected_tier) setSelectedTier(data.selected_tier);
        
        // If profile is already complete, redirect to profile page
        const isComplete = !!(data.date_of_birth && data.trading_experience && data.selected_tier);
        if (isComplete) {
          console.log("Profile already complete, redirecting to profile page");
          navigate('/profile');
        }
      } catch (error) {
        console.error('Error checking profile:', error);
      }
    };
    
    fetchExistingProfile();
  }, [user, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!dateOfBirth || !tradingExperience || !selectedTier) {
      toast({
        title: "Missing information",
        description: "Please fill in all fields to complete your profile.",
        variant: "destructive"
      });
      return;
    }
    
    setLoading(true);
    
    try {
      console.log("Updating profile with:", { dateOfBirth, tradingExperience, selectedTier });
      await updateProfile({
        date_of_birth: dateOfBirth,
        trading_experience: tradingExperience,
        selected_tier: selectedTier
      });
      
      // Verify profile was actually updated
      const isComplete = await checkProfileComplete();
      console.log("Profile complete after update:", isComplete);
      
      if (isComplete) {
        toast({
          title: "Profile Updated",
          description: "Your profile has been completed successfully."
        });
        
        navigate('/profile');
      } else {
        throw new Error("Profile update failed verification");
      }
    } catch (error: any) {
      console.error('Error updating profile:', error);
      toast({
        title: "Error",
        description: error.message || "Failed to update profile. Please try again.",
        variant: "destructive"
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Complete Your Profile</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Please provide the following information to complete your profile.</p>
          </div>
          
          <form onSubmit={handleSubmit} className="border-t border-gray-200">
            <div className="px-4 py-5 space-y-6 sm:p-6">
              <div>
                <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700">Date of Birth</label>
                <Input
                  id="dateOfBirth"
                  type="date"
                  required
                  className="mt-1"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                />
                <p className="mt-1 text-sm text-gray-500">You must be at least 18 years old</p>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">Trading Experience</label>
                <RadioGroup 
                  value={tradingExperience} 
                  onValueChange={setTradingExperience}
                  className="flex flex-col space-y-2 mt-2"
                  required
                >
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="beginner" id="beginner" />
                    <Label htmlFor="beginner">Beginner (Less than 1 year)</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="intermediate" id="intermediate" />
                    <Label htmlFor="intermediate">Intermediate (1-3 years)</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="advanced" id="advanced" />
                    <Label htmlFor="advanced">Advanced (3-5 years)</Label>
                  </div>
                  <div className="flex items-center space-x-2">
                    <RadioGroupItem value="expert" id="expert" />
                    <Label htmlFor="expert">Expert (5+ years)</Label>
                  </div>
                </RadioGroup>
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700">Subscription Tier</label>
                <RadioGroup 
                  value={selectedTier} 
                  onValueChange={setSelectedTier}
                  className="grid grid-cols-1 gap-4 mt-2"
                  required
                >
                  <div className={`relative rounded-lg border p-4 ${selectedTier === 'basic' ? 'border-primary bg-primary/5' : 'border-gray-200'}`}>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="basic" id="basic" />
                      <div>
                        <Label htmlFor="basic" className="text-lg font-medium">Basic</Label>
                        <p className="text-sm text-gray-500">Essential market insights for beginners</p>
                        <p className="font-bold mt-2">$29/month</p>
                      </div>
                    </div>
                  </div>
                  
                  <div className={`relative rounded-lg border p-4 ${selectedTier === 'pro' ? 'border-primary bg-primary/5' : 'border-gray-200'}`}>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="pro" id="pro" />
                      <div>
                        <Label htmlFor="pro" className="text-lg font-medium">Pro</Label>
                        <p className="text-sm text-gray-500">Advanced analytics for serious traders</p>
                        <p className="font-bold mt-2">$79/month</p>
                      </div>
                    </div>
                  </div>
                  
                  <div className={`relative rounded-lg border p-4 ${selectedTier === 'elite' ? 'border-primary bg-primary/5' : 'border-gray-200'}`}>
                    <div className="flex items-center space-x-2">
                      <RadioGroupItem value="elite" id="elite" />
                      <div>
                        <Label htmlFor="elite" className="text-lg font-medium">Elite</Label>
                        <p className="text-sm text-gray-500">Premium features for professional traders</p>
                        <p className="font-bold mt-2">$199/month</p>
                      </div>
                    </div>
                  </div>
                </RadioGroup>
              </div>
            </div>
            
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <Button type="submit" disabled={loading}>
                {loading ? "Saving..." : "Complete Profile"}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProfileSetup;
