
import React, { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from "@/components/ui/table";
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { 
  PlusCircle, 
  MoreVertical, 
  Pencil, 
  Trash, 
  RefreshCw, 
  TrendingUp, 
  TrendingDown, 
  Filter, 
  Search 
} from "lucide-react";
import { useSignals } from "@/hooks/useSignals";
import { Input } from "@/components/ui/input";
import { SignalData } from "@/components/trading/SignalCard";

interface SignalListProps {
  onEdit: (signalId: string) => void;
  onCreateNew: () => void;
}

const SignalList = ({ onEdit, onCreateNew }: SignalListProps) => {
  const { signals, isLoading, error, refetch } = useSignals();
  const [searchTerm, setSearchTerm] = useState("");
  const [signalToDelete, setSignalToDelete] = useState<string | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { toast } = useToast();

  const handleRefresh = () => {
    refetch();
    toast({
      title: "Refreshed",
      description: "Signal list has been updated",
    });
  };

  const handleDeleteSignal = async () => {
    if (!signalToDelete) return;
    
    try {
      setIsDeleting(true);
      
      // First delete all related media
      await supabase
        .from('signal_media')
        .delete()
        .eq('signal_id', signalToDelete);
      
      // Then delete all history entries
      await supabase
        .from('signal_history')
        .delete()
        .eq('signal_id', signalToDelete);
      
      // Finally delete the signal itself
      const { error } = await supabase
        .from('trading_signals')
        .delete()
        .eq('id', signalToDelete);
      
      if (error) throw error;
      
      toast({
        title: "Signal Deleted",
        description: "The trading signal has been permanently removed",
      });
      
      refetch();
    } catch (error: any) {
      console.error("Error deleting signal:", error);
      toast({
        title: "Error",
        description: error.message || "Failed to delete signal",
        variant: "destructive",
      });
    } finally {
      setSignalToDelete(null);
      setIsDeleting(false);
    }
  };

  const filteredSignals = signals.filter(signal => 
    signal.symbol.toLowerCase().includes(searchTerm.toLowerCase()) ||
    signal.analysis?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="space-y-4">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div className="relative w-full sm:w-80">
          <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            type="search"
            placeholder="Search signals..."
            className="pl-8"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="flex items-center gap-2 w-full sm:w-auto">
          <Button 
            variant="outline" 
            size="sm" 
            onClick={handleRefresh}
            disabled={isLoading}
            className="flex-1 sm:flex-initial"
          >
            <RefreshCw className="h-4 w-4 mr-2" />
            Refresh
          </Button>
          <Button 
            onClick={onCreateNew}
            className="flex-1 sm:flex-initial"
          >
            <PlusCircle className="h-4 w-4 mr-2" />
            New Signal
          </Button>
        </div>
      </div>

      {isLoading ? (
        <div className="flex justify-center p-8">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </div>
      ) : error ? (
        <div className="bg-destructive/10 text-destructive p-4 rounded-md">
          <p>Error loading signals: {error}</p>
        </div>
      ) : filteredSignals.length === 0 ? (
        <div className="text-center p-8 border rounded-md bg-muted/20">
          <p className="text-muted-foreground">No trading signals found.</p>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={onCreateNew} 
            className="mt-4"
          >
            <PlusCircle className="h-4 w-4 mr-2" />
            Create Your First Signal
          </Button>
        </div>
      ) : (
        <div className="border rounded-md overflow-hidden">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Symbol</TableHead>
                <TableHead>Signal</TableHead>
                <TableHead className="hidden md:table-cell">Entry</TableHead>
                <TableHead className="hidden md:table-cell">Target</TableHead>
                <TableHead className="hidden md:table-cell">NEO</TableHead>
                <TableHead className="hidden md:table-cell">Created</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredSignals.map((signal) => (
                <TableRow key={signal.id}>
                  <TableCell className="font-medium">{signal.symbol}</TableCell>
                  <TableCell>
                    <div className={`flex items-center gap-1 ${signal.signal === "Long" ? "text-green-600" : "text-red-600"}`}>
                      {signal.signal === "Long" ? <TrendingUp className="h-4 w-4" /> : <TrendingDown className="h-4 w-4" />}
                      {signal.signal}
                    </div>
                  </TableCell>
                  <TableCell className="hidden md:table-cell">${signal.entryPrice}</TableCell>
                  <TableCell className="hidden md:table-cell">${signal.targetPrice}</TableCell>
                  <TableCell className="hidden md:table-cell">
                    {signal.neoScore !== undefined ? signal.neoScore : '-'}
                  </TableCell>
                  <TableCell className="hidden md:table-cell">
                    {new Date(signal.createdAt || "").toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
                          <MoreVertical className="h-4 w-4" />
                          <span className="sr-only">Open menu</span>
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuLabel>Actions</DropdownMenuLabel>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem onClick={() => onEdit(signal.id)}>
                          <Pencil className="h-4 w-4 mr-2" />
                          Edit Signal
                        </DropdownMenuItem>
                        <AlertDialog>
                          <AlertDialogTrigger asChild>
                            <DropdownMenuItem
                              onSelect={(e) => {
                                e.preventDefault();
                                setSignalToDelete(signal.id);
                              }}
                              className="text-destructive focus:text-destructive"
                            >
                              <Trash className="h-4 w-4 mr-2" />
                              Delete Signal
                            </DropdownMenuItem>
                          </AlertDialogTrigger>
                          <AlertDialogContent>
                            <AlertDialogHeader>
                              <AlertDialogTitle>Delete Signal</AlertDialogTitle>
                              <AlertDialogDescription>
                                Are you sure you want to delete the {signal.symbol} {signal.signal} signal? 
                                This action cannot be undone and will remove all associated media and history.
                              </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                              <AlertDialogCancel>Cancel</AlertDialogCancel>
                              <AlertDialogAction 
                                onClick={handleDeleteSignal}
                                disabled={isDeleting}
                                className="bg-destructive hover:bg-destructive/90"
                              >
                                {isDeleting ? "Deleting..." : "Delete"}
                              </AlertDialogAction>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialog>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default SignalList;
