
import { useEffect, useState } from "react";
import { useAuth } from "@/contexts/AuthContext";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { supabase } from "@/integrations/supabase/client";
import { LogOut, User, ShieldCheck, LineChart } from "lucide-react";
import { Link } from "react-router-dom";

interface Profile {
  full_name: string | null;
  username: string | null;
  date_of_birth: string | null;
  trading_experience: string | null;
  selected_tier: string | null;
  avatar_url: string | null;
}

const Profile = () => {
  const { user, signOut, isAdmin, checkIsAdmin } = useAuth();
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!user) return;
      
      try {
        setLoading(true);
        
        // Refresh admin status on profile page load
        await checkIsAdmin();
        
        const { data, error } = await supabase
          .from('profiles')
          .select('*')
          .eq('id', user.id)
          .single();
        
        if (error) {
          throw error;
        }
        
        setProfile(data as Profile);
      } catch (error) {
        console.error('Error fetching profile:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user, checkIsAdmin]);

  const formatDate = (dateString: string | null) => {
    if (!dateString) return 'Not provided';
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const getTierLabel = (tier: string | null) => {
    if (!tier) return 'Not selected';
    
    const tiers: Record<string, { name: string, price: string }> = {
      basic: { name: 'Basic', price: '$29/month' },
      pro: { name: 'Pro', price: '$79/month' },
      elite: { name: 'Elite', price: '$199/month' },
    };
    
    const tierInfo = tiers[tier.toLowerCase()];
    return tierInfo ? `${tierInfo.name} (${tierInfo.price})` : tier;
  };

  const getExperienceLabel = (experience: string | null) => {
    if (!experience) return 'Not provided';
    
    const experiences: Record<string, string> = {
      beginner: 'Beginner (Less than 1 year)',
      intermediate: 'Intermediate (1-3 years)',
      advanced: 'Advanced (3-5 years)',
      expert: 'Expert (5+ years)',
    };
    
    return experiences[experience.toLowerCase()] || experience;
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                User Profile
                {isAdmin && (
                  <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                    <ShieldCheck className="h-3 w-3 mr-1" />
                    Admin
                  </span>
                )}
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and account information</p>
            </div>
            <div className="flex space-x-2">
              <Button asChild variant="default">
                <Link to="/app" className="flex items-center space-x-2">
                  <LineChart className="h-4 w-4" />
                  <span>Trading Platform</span>
                </Link>
              </Button>
              <Button variant="outline" onClick={signOut} className="flex items-center space-x-2">
                <LogOut className="h-4 w-4" />
                <span>Sign Out</span>
              </Button>
            </div>
          </div>
          
          {loading ? (
            <div className="px-4 py-5 sm:p-6">
              <div className="space-y-4">
                <Skeleton className="h-4 w-3/4" />
                <Skeleton className="h-4 w-1/2" />
                <Skeleton className="h-4 w-5/6" />
                <Skeleton className="h-4 w-2/3" />
              </div>
            </div>
          ) : profile ? (
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Full name</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{profile.full_name || 'Not provided'}</dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Email address</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{user?.email}</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Date of birth</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{formatDate(profile.date_of_birth)}</dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Trading experience</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{getExperienceLabel(profile.trading_experience)}</dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Subscription tier</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {getTierLabel(profile.selected_tier)}
                    </span>
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Role</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${isAdmin ? 'bg-indigo-100 text-indigo-800' : 'bg-blue-100 text-blue-800'}`}>
                      {isAdmin ? 'Administrator' : 'User'}
                    </span>
                  </dd>
                </div>
              </dl>
            </div>
          ) : (
            <div className="px-4 py-5 sm:p-6 text-center">
              <User className="h-12 w-12 text-gray-400 mx-auto" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">No profile found</h3>
              <p className="mt-1 text-sm text-gray-500">We couldn't find your profile information.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
