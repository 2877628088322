
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBlogs } from "@/hooks/useBlogs";
import { BLOG_CATEGORIES } from "@/types/blog";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Input } from "@/components/ui/input";
import { Separator } from "@/components/ui/separator";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { BookMarked, Calendar, Search, Tag as TagIcon } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import BlogCard from "@/components/blog/BlogCard";
import HomeHeader from "@/components/home/HomeHeader";

const Blogs = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);

  const { useBlogsQuery, useFilteredBlogsQuery, useBlogsByTagQuery } = useBlogs();

  // Fetch all blogs
  const { data: allBlogs = [], isLoading } = useBlogsQuery();

  // Fetch filtered blogs by category
  const { data: categoryBlogs = [] } = useFilteredBlogsQuery(selectedCategory || "", {
    enabled: !!selectedCategory,
  });

  // Fetch filtered blogs by tag
  const { data: tagBlogs = [] } = useBlogsByTagQuery(selectedTag || "", {
    enabled: !!selectedTag,
  });

  // Determine which blogs to display
  const blogs = selectedCategory
    ? categoryBlogs
    : selectedTag
    ? tagBlogs
    : allBlogs;

  // Filter by search query
  const filteredBlogs = blogs.filter((blog) =>
    blog.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    blog.summary.toLowerCase().includes(searchQuery.toLowerCase()) ||
    blog.content.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Extract all unique tags from blogs
  const allTags = Array.from(
    new Set(
      allBlogs.flatMap((blog) => blog.tags || [])
    )
  );

  // Get the featured blog (newest blog)
  const featuredBlog = allBlogs.length > 0 ? allBlogs[0] : null;
  
  // Get recent blogs excluding the featured one
  const recentBlogs = allBlogs.slice(1, 4);

  const handleClearFilters = () => {
    setSelectedCategory(null);
    setSelectedTag(null);
    setSearchQuery("");
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
      <HomeHeader />

      <main className="flex-1 py-6">
        <div className="container px-4 mx-auto">
          <div className="mb-8">
            <h1 className="text-3xl font-bold mb-2">Market Insights Blog</h1>
            <p className="text-muted-foreground">
              Latest analysis, trading insights, and market updates
            </p>
          </div>

          {isLoading ? (
            <div className="flex justify-center py-12">
              <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
            </div>
          ) : allBlogs.length === 0 ? (
            <div className="text-center py-12">
              <BookMarked className="h-12 w-12 mx-auto text-muted-foreground mb-4" />
              <h3 className="text-xl font-medium mb-2">No blog posts yet</h3>
              <p className="text-muted-foreground mb-4">
                Check back soon for market analysis and trading insights
              </p>
            </div>
          ) : (
            <>
              {/* Featured section */}
              {featuredBlog && !selectedCategory && !selectedTag && searchQuery === "" && (
                <section className="mb-12">
                  <h2 className="text-2xl font-bold mb-6 flex items-center">
                    <Calendar className="h-5 w-5 mr-2" />
                    Latest Analysis
                  </h2>
                  <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                    <div className="lg:col-span-2">
                      <BlogCard post={featuredBlog} featured={true} />
                    </div>
                    <div className="lg:col-span-1 space-y-8 flex flex-col">
                      <Card className="flex-1">
                        <CardHeader>
                          <CardTitle className="flex items-center text-lg">
                            <TagIcon className="h-4 w-4 mr-2" />
                            Categories
                          </CardTitle>
                        </CardHeader>
                        <CardContent>
                          <div className="flex flex-wrap gap-2">
                            {BLOG_CATEGORIES.map((category) => (
                              <Badge 
                                key={category} 
                                variant={selectedCategory === category ? "default" : "outline"}
                                className="cursor-pointer"
                                onClick={() => setSelectedCategory(
                                  selectedCategory === category ? null : category
                                )}
                              >
                                {category}
                              </Badge>
                            ))}
                          </div>
                        </CardContent>
                      </Card>

                      <Card className="flex-1">
                        <CardHeader>
                          <CardTitle className="flex items-center text-lg">
                            <Search className="h-4 w-4 mr-2" />
                            Search
                          </CardTitle>
                        </CardHeader>
                        <CardContent>
                          <Input
                            placeholder="Search blogs..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            className="mb-4"
                          />
                        </CardContent>
                      </Card>
                    </div>
                  </div>
                </section>
              )}

              {/* Filter status */}
              {(selectedCategory || selectedTag || searchQuery) && (
                <div className="mb-6 flex items-center justify-between">
                  <div>
                    {selectedCategory && <Badge className="mr-2">Category: {selectedCategory}</Badge>}
                    {selectedTag && <Badge className="mr-2">Tag: {selectedTag}</Badge>}
                    {searchQuery && <Badge>Search: "{searchQuery}"</Badge>}
                  </div>
                  <Button variant="outline" size="sm" onClick={handleClearFilters}>
                    Clear Filters
                  </Button>
                </div>
              )}

              {/* Recent posts */}
              {!selectedCategory && !selectedTag && searchQuery === "" && recentBlogs.length > 0 && (
                <section className="mb-12">
                  <h2 className="text-2xl font-bold mb-6">Recent Posts</h2>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {recentBlogs.map((blog) => (
                      <BlogCard key={blog.id} post={blog} />
                    ))}
                  </div>
                </section>
              )}

              {/* All blogs (or filtered results) */}
              <section>
                <h2 className="text-2xl font-bold mb-6">
                  {selectedCategory
                    ? `${selectedCategory} Posts`
                    : selectedTag
                    ? `Posts tagged with "${selectedTag}"`
                    : searchQuery
                    ? `Search Results for "${searchQuery}"`
                    : "All Posts"}
                </h2>
                
                {filteredBlogs.length === 0 ? (
                  <div className="text-center py-12 bg-muted/20 rounded-lg">
                    <h3 className="text-xl font-medium mb-2">No matching posts found</h3>
                    <p className="text-muted-foreground mb-4">
                      Try adjusting your filters or search criteria
                    </p>
                    <Button variant="outline" onClick={handleClearFilters}>
                      Clear Filters
                    </Button>
                  </div>
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filteredBlogs.map((blog) => (
                      <BlogCard key={blog.id} post={blog} />
                    ))}
                  </div>
                )}
              </section>

              {/* Tags section */}
              {allTags.length > 0 && (
                <section className="mt-12">
                  <Separator className="mb-6" />
                  <h2 className="text-2xl font-bold mb-4">Tags</h2>
                  <div className="flex flex-wrap gap-2">
                    {allTags.map((tag) => (
                      <Badge 
                        key={tag} 
                        variant={selectedTag === tag ? "default" : "secondary"}
                        className="cursor-pointer"
                        onClick={() => setSelectedTag(
                          selectedTag === tag ? null : tag
                        )}
                      >
                        {tag}
                      </Badge>
                    ))}
                  </div>
                </section>
              )}
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default Blogs;
