
import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableHeader, 
  TableRow 
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { BarChart, Info, Edit, Trash2, Plus } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useIsMobile } from "@/hooks/use-mobile";
import { usePortfolio, PortfolioHolding } from "@/hooks/usePortfolio";
import PortfolioHoldingForm from "./PortfolioHoldingForm";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Badge } from "@/components/ui/badge";

const PortfolioHoldings = () => {
  const isMobile = useIsMobile();
  const { holdings, addHolding, updateHolding, deleteHolding, isLoading } = usePortfolio();
  
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedHolding, setSelectedHolding] = useState<PortfolioHolding | null>(null);
  
  const handleAddPosition = (data: Omit<PortfolioHolding, 'id' | 'user_id'>) => {
    addHolding.mutate(data, {
      onSuccess: () => {
        setIsAddFormOpen(false);
      }
    });
  };
  
  const handleEditPosition = (data: Omit<PortfolioHolding, 'id' | 'user_id'>) => {
    if (!selectedHolding) return;
    
    updateHolding.mutate({
      id: selectedHolding.id,
      ...data
    }, {
      onSuccess: () => {
        setIsEditFormOpen(false);
        setSelectedHolding(null);
      }
    });
  };
  
  const handleDeletePosition = () => {
    if (!selectedHolding) return;
    
    deleteHolding.mutate(selectedHolding.id, {
      onSuccess: () => {
        setIsDeleteDialogOpen(false);
        setSelectedHolding(null);
      }
    });
  };
  
  const openEditForm = (holding: PortfolioHolding) => {
    setSelectedHolding(holding);
    setIsEditFormOpen(true);
  };
  
  const openDeleteDialog = (holding: PortfolioHolding) => {
    setSelectedHolding(holding);
    setIsDeleteDialogOpen(true);
  };
  
  const getTransactionBadge = (type: 'buy' | 'sell') => {
    return type === 'buy' 
      ? <Badge className="bg-green-600">Buy</Badge> 
      : <Badge className="bg-red-600">Sell</Badge>;
  };
  
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <div>
          <CardTitle>Holdings</CardTitle>
          <CardDescription>
            Your current portfolio allocation and performance
          </CardDescription>
        </div>
        <Button 
          size="sm" 
          onClick={() => setIsAddFormOpen(true)}
          className="flex items-center gap-1"
        >
          <Plus className="h-4 w-4" />
          {!isMobile && "Add Position"}
        </Button>
      </CardHeader>
      <CardContent>
        <div className="rounded-md border overflow-hidden">
          <ScrollArea className="w-full">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Symbol</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead className="hidden md:table-cell">Allocation</TableHead>
                  <TableHead>Value</TableHead>
                  <TableHead>Change</TableHead>
                  <TableHead>Entry Price</TableHead>
                  <TableHead>Current Price</TableHead>
                  <TableHead className="text-right">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={isMobile ? 7 : 8} className="text-center py-6 text-muted-foreground">
                      Loading your portfolio...
                    </TableCell>
                  </TableRow>
                ) : holdings.length > 0 ? (
                  holdings.map((holding) => (
                    <TableRow key={holding.id}>
                      <TableCell className="font-medium">{holding.symbol}</TableCell>
                      <TableCell>{getTransactionBadge(holding.transaction_type)}</TableCell>
                      <TableCell className="hidden md:table-cell">{holding.allocation?.toFixed(2)}%</TableCell>
                      <TableCell>${holding.value?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                      <TableCell className={(holding.change || 0) > 0 ? "text-green-600" : "text-red-600"}>
                        {(holding.change || 0) > 0 ? "+" : ""}{holding.change?.toFixed(2)}%
                      </TableCell>
                      <TableCell>${holding.entry_price.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                      <TableCell>${holding.current_price?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                      <TableCell className="text-right">
                        <Button 
                          variant="ghost" 
                          size="sm" 
                          className="h-8 w-8 p-0 mr-2"
                          aria-label={`View details for ${holding.symbol}`}
                        >
                          <BarChart className="h-4 w-4" aria-hidden="true" />
                          <span className="sr-only">View Details</span>
                        </Button>
                        <Button 
                          variant="ghost" 
                          size="sm" 
                          className="h-8 w-8 p-0 mr-2"
                          aria-label={`Edit ${holding.symbol}`}
                          onClick={() => openEditForm(holding)}
                        >
                          <Edit className="h-4 w-4" aria-hidden="true" />
                          <span className="sr-only">Edit</span>
                        </Button>
                        <Button 
                          variant="ghost" 
                          size="sm" 
                          className="h-8 w-8 p-0"
                          aria-label={`Delete ${holding.symbol}`}
                          onClick={() => openDeleteDialog(holding)}
                        >
                          <Trash2 className="h-4 w-4 text-red-500" aria-hidden="true" />
                          <span className="sr-only">Delete</span>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={isMobile ? 7 : 8} className="text-center py-6 text-muted-foreground">
                      No holdings available. Click "Add Position" to create your portfolio.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </ScrollArea>
        </div>
      </CardContent>
      
      {/* Add Position Form */}
      <PortfolioHoldingForm 
        open={isAddFormOpen}
        onOpenChange={setIsAddFormOpen}
        onSubmit={handleAddPosition}
      />
      
      {/* Edit Position Form */}
      <PortfolioHoldingForm 
        open={isEditFormOpen}
        onOpenChange={setIsEditFormOpen}
        onSubmit={handleEditPosition}
        initialData={selectedHolding || undefined}
        isEditing
      />
      
      {/* Delete Confirmation Dialog */}
      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              This will permanently delete your {selectedHolding?.symbol} position from your portfolio.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDeletePosition} 
              className="bg-red-500 hover:bg-red-600"
            >
              Delete
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Card>
  );
};

export default PortfolioHoldings;
