
import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useQuickUpdates, QuickUpdate } from "@/hooks/useQuickUpdates";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { format } from "date-fns";
import { Bell } from "lucide-react";
import { Skeleton } from "@/components/ui/skeleton";

const QuickUpdates = () => {
  const { updates, isLoading } = useQuickUpdates();
  const [selectedUpdate, setSelectedUpdate] = useState<QuickUpdate | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleUpdateClick = (update: QuickUpdate) => {
    setSelectedUpdate(update);
    setDialogOpen(true);
  };

  const formatDate = (dateString: string) => {
    try {
      return format(new Date(dateString), 'MMM d, h:mm a');
    } catch (error) {
      return dateString;
    }
  };

  return (
    <>
      <Card>
        <CardHeader className="flex flex-row items-center justify-between pb-2">
          <CardTitle className="text-sm font-medium">Quick Updates</CardTitle>
          <Bell className="h-4 w-4 text-muted-foreground" />
        </CardHeader>
        <CardContent>
          {isLoading ? (
            <div className="space-y-3">
              {[1, 2, 3].map((i) => (
                <div key={i} className="flex flex-col gap-1">
                  <Skeleton className="h-4 w-3/4" />
                  <Skeleton className="h-3 w-1/4" />
                </div>
              ))}
            </div>
          ) : updates && updates.length > 0 ? (
            <div className="space-y-3">
              {updates.map((update) => (
                <div 
                  key={update.id} 
                  className="border-b pb-2 last:border-0 cursor-pointer hover:bg-muted/50 -mx-2 px-2 rounded"
                  onClick={() => handleUpdateClick(update)}
                >
                  <h4 className="text-sm font-medium truncate">{update.title}</h4>
                  <p className="text-xs text-muted-foreground">
                    {formatDate(update.created_at)}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center text-muted-foreground text-sm py-4">No updates available</p>
          )}
        </CardContent>
      </Card>

      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        {selectedUpdate && (
          <DialogContent className="sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle>{selectedUpdate.title}</DialogTitle>
              <DialogDescription className="text-xs">
                {formatDate(selectedUpdate.created_at)}
              </DialogDescription>
            </DialogHeader>
            <div className="mt-2">
              <p className="text-sm whitespace-pre-wrap">{selectedUpdate.content}</p>
            </div>
          </DialogContent>
        )}
      </Dialog>
    </>
  );
};

export default QuickUpdates;
