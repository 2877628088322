
import { supabase } from '@/integrations/supabase/client';
import { User } from '@supabase/supabase-js';
import { toast } from '@/hooks/use-toast';

export const checkProfileComplete = async (user: User | null): Promise<boolean> => {
  if (!user) return false;
  
  try {
    console.log("Checking profile completion for user:", user.id);
    const { data, error } = await supabase
      .from('profiles')
      .select('date_of_birth, trading_experience, selected_tier')
      .eq('id', user.id)
      .single();
    
    if (error) throw error;
    
    const isComplete = !!(data.date_of_birth && data.trading_experience && data.selected_tier);
    console.log("Profile check result:", { data, isComplete });
    return isComplete;
  } catch (error) {
    console.error('Error checking profile completion:', error);
    return false;
  }
};

export const checkIsAdmin = async (user: User | null): Promise<boolean> => {
  if (!user) return false;
  
  try {
    console.log("Checking admin status for user:", user.id);
    const { data, error } = await supabase
      .rpc('is_admin', { user_id: user.id });
    
    if (error) throw error;
    
    const userIsAdmin = !!data;
    console.log("Admin check result:", userIsAdmin);
    return userIsAdmin;
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};

export const updateProfile = async (user: User | null, data: any): Promise<void> => {
  try {
    if (!user) throw new Error("No user logged in");

    const { error } = await supabase
      .from('profiles')
      .update(data)
      .eq('id', user.id);

    if (error) throw error;

    toast({
      title: "Profile updated",
      description: "Your profile has been updated successfully.",
    });
  } catch (error: any) {
    toast({
      title: "Error updating profile",
      description: error.message || "Something went wrong",
      variant: "destructive"
    });
    throw error;
  }
};
