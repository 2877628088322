
import { useState, useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';

export interface QuickUpdate {
  id: string;
  title: string;
  content: string;
  created_at: string;
  updated_at: string | null;
  created_by: string | null;
}

export const useQuickUpdates = () => {
  const [updates, setUpdates] = useState<QuickUpdate[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { toast } = useToast();

  const fetchUpdates = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      // Use a direct query to fetch updates
      const { data, error: fetchError } = await supabase
        .from('quick_updates')
        .select('*')
        .order('created_at', { ascending: false })
        .limit(5);
      
      if (fetchError) throw fetchError;
      
      setUpdates(data || []);
    } catch (err: any) {
      console.error('Error fetching quick updates:', err);
      setError(err.message);
      toast({
        title: "Error",
        description: "Failed to fetch quick updates",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUpdates();
    
    // Subscribe to realtime changes on the quick_updates table
    const channel = supabase
      .channel('schema-db-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'quick_updates'
        },
        () => {
          fetchUpdates();
        }
      )
      .subscribe();
      
    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  return {
    updates,
    isLoading,
    error,
    refetch: fetchUpdates
  };
};
