
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { TrendingUp, TrendingDown, BarChart2, RefreshCw } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";
import { usePortfolio } from "@/hooks/usePortfolio";
import { useAuth } from "@/contexts/AuthContext";
import { useMarketPrices } from "@/hooks/useMarketPrices";
import { Link } from "react-router-dom";

export const PortfolioSummaryCard = () => {
  const { user } = useAuth();
  const { portfolioSummary, holdings, isLoading } = usePortfolio();
  const { refreshPrices } = useMarketPrices();
  const [isRising, setIsRising] = useState(true);
  const [portfolioValue, setPortfolioValue] = useState(0);
  const [dailyChange, setDailyChange] = useState(0);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const isMobile = useIsMobile();
  
  useEffect(() => {
    if (!isLoading && holdings.length > 0) {
      setPortfolioValue(portfolioSummary.totalValue || 0);
      setDailyChange(portfolioSummary.dailyChange || 0);
      setIsRising((portfolioSummary.dailyChange || 0) > 0);
    } else if (!user) {
      // Demo data for non-authenticated users
      const demoValue = 180610;
      const demoChange = parseFloat((Math.random() * 5 - 2).toFixed(2));
      setPortfolioValue(demoValue);
      setDailyChange(demoChange);
      setIsRising(demoChange > 0);
      
      // Simulate real-time updates for demo
      const interval = setInterval(() => {
        const newValue = demoValue + (Math.random() * 1000 - 500);
        const newChange = parseFloat((Math.random() * 5 - 2).toFixed(2));
        setPortfolioValue(newValue);
        setDailyChange(newChange);
        setIsRising(newChange > 0);
      }, 5000);
      
      return () => clearInterval(interval);
    }
  }, [isLoading, holdings, portfolioSummary, user]);
  
  const handleRefreshPrices = async () => {
    if (!user || isRefreshing) return;
    
    setIsRefreshing(true);
    
    // Get symbols from holdings
    const symbols = holdings.map(h => h.symbol);
    
    try {
      await refreshPrices(symbols);
    } catch (error) {
      console.error("Error refreshing prices:", error);
    } finally {
      // Add small delay to show refreshing state
      setTimeout(() => {
        setIsRefreshing(false);
      }, 1500);
    }
  };
  
  return (
    <motion.div
      animate={{ 
        scale: [1, 1.01, 1],
        transition: { duration: 2, repeat: Infinity, repeatType: "reverse" }
      }}
      className="border rounded-md p-3 sm:p-4 bg-white"
    >
      <div className="flex justify-between items-center mb-3 sm:mb-4">
        <h3 className="font-semibold text-sm sm:text-base">Portfolio Summary</h3>
        <div className="flex items-center gap-2">
          {user && (
            <Button 
              variant="outline" 
              size="sm" 
              className="h-6 w-6 sm:h-8 sm:w-8 p-0"
              onClick={handleRefreshPrices}
              disabled={isRefreshing}
              title="Refresh Prices"
            >
              <RefreshCw className={`h-3 w-3 sm:h-4 sm:w-4 ${isRefreshing ? 'animate-spin' : ''}`} />
              <span className="sr-only">Refresh Prices</span>
            </Button>
          )}
          <Button 
            variant="outline" 
            size="sm" 
            className="h-6 w-6 sm:h-8 sm:w-8 p-0"
            asChild
          >
            <Link to="/app">
              <BarChart2 className="h-3 w-3 sm:h-4 sm:w-4" />
            </Link>
          </Button>
        </div>
      </div>
      
      <div className="space-y-2 sm:space-y-4">
        <div>
          <div className="text-xs sm:text-sm text-muted-foreground">Total Value</div>
          <div className="text-lg sm:text-2xl font-bold">${portfolioValue.toLocaleString(undefined, {minimumFractionDigits: 2, maximumFractionDigits: 2})}</div>
        </div>
        
        <div className="flex items-center">
          <div className={`text-xs sm:text-sm ${isRising ? 'text-green-600' : 'text-red-600'} font-medium flex items-center`}>
            {isRising ? <TrendingUp className="mr-1 h-3 w-3 sm:h-4 sm:w-4" /> : <TrendingDown className="mr-1 h-3 w-3 sm:h-4 sm:w-4" />}
            {isRising ? '+' : ''}{dailyChange.toFixed(2)}% today
          </div>
        </div>
        
        <div className="grid grid-cols-2 gap-1 sm:gap-2 pt-1 sm:pt-2">
          <div>
            <div className="text-[10px] sm:text-xs text-muted-foreground">Top Performer</div>
            <div className="font-medium text-xs sm:text-sm text-green-600">
              {portfolioSummary.topPerformer ? (
                `${portfolioSummary.topPerformer.symbol} ${portfolioSummary.topPerformer.change > 0 ? '+' : ''}${portfolioSummary.topPerformer.change?.toFixed(2)}%`
              ) : (
                "NVDA +2.42%"
              )}
            </div>
          </div>
          <div>
            <div className="text-[10px] sm:text-xs text-muted-foreground">Bottom Performer</div>
            <div className="font-medium text-xs sm:text-sm text-red-600">
              {portfolioSummary.worstPerformer ? (
                `${portfolioSummary.worstPerformer.symbol} ${portfolioSummary.worstPerformer.change > 0 ? '+' : ''}${portfolioSummary.worstPerformer.change?.toFixed(2)}%`
              ) : (
                "AMZN -1.15%"
              )}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
