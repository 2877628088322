
import { useState, useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';

export interface SignalStats {
  id: string;
  total_signals: number;
  open_in_money: number;
  open_out_of_money: number;
  stop_loss_hit: number;
  target_hit: number;
  closed_in_profit: number;
  closed_in_loss: number;
  updated_at: string | null;
}

export const useSignalStats = () => {
  const [stats, setStats] = useState<SignalStats | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { toast } = useToast();

  const fetchStats = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      // Using a raw query to bypass TypeScript issues with the new table
      const { data, error: fetchError } = await supabase
        .from('signal_stats')
        .select('*')
        .single();
      
      if (fetchError) throw fetchError;
      
      // Cast the data to our SignalStats type
      setStats(data as unknown as SignalStats);
    } catch (err: any) {
      console.error('Error fetching signal stats:', err);
      setError(err.message);
      toast({
        title: "Error",
        description: "Failed to fetch signal statistics",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchStats();
    
    // Subscribe to realtime changes on the signal_stats table
    const channel = supabase
      .channel('schema-db-changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'signal_stats'
        },
        () => {
          fetchStats();
        }
      )
      .subscribe();
      
    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  return {
    stats,
    isLoading,
    error,
    refetch: fetchStats
  };
};
