
import React from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, CardFooter, CardHeader } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Calendar, Clock } from "lucide-react";
import { BlogPost } from "@/types/blog";

interface BlogCardProps {
  post: BlogPost;
  featured?: boolean;
}

const BlogCard = ({ post, featured = false }: BlogCardProps) => {
  return (
    <Card className={`overflow-hidden transition-all duration-200 hover:shadow-md ${featured ? 'h-full' : ''}`}>
      {post.image_url && (
        <div className="relative w-full h-48 overflow-hidden">
          <img 
            src={post.image_url} 
            alt={post.title} 
            className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
          />
          <Badge className="absolute top-3 right-3 bg-primary/90">{post.category}</Badge>
        </div>
      )}
      <CardHeader className="pb-2">
        <div className="flex items-center text-sm text-muted-foreground space-x-3 mb-2">
          <span className="flex items-center">
            <Calendar className="mr-1 h-3.5 w-3.5" />
            {new Date(post.published_at).toLocaleDateString()}
          </span>
          <span className="flex items-center">
            <Clock className="mr-1 h-3.5 w-3.5" />
            {post.read_time} min read
          </span>
        </div>
        <Link to={`/blogs/${post.slug}`} className="hover:underline">
          <h3 className={`font-semibold line-clamp-2 ${featured ? 'text-2xl' : 'text-lg'}`}>
            {post.title}
          </h3>
        </Link>
      </CardHeader>
      <CardContent>
        <p className="text-muted-foreground line-clamp-3">
          {post.summary}
        </p>
      </CardContent>
      <CardFooter className="pt-0 flex justify-between items-center">
        <span className="text-sm font-medium">By {post.author}</span>
        <Link 
          to={`/blogs/${post.slug}`} 
          className="text-sm font-medium text-primary hover:underline"
        >
          Read more
        </Link>
      </CardFooter>
    </Card>
  );
};

export default BlogCard;
