
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@/contexts/AuthContext';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { user, loading, checkProfileComplete } = useAuth();
  const [isChecking, setIsChecking] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      if (!loading && user) {
        // Only check profile completion if not already on the profile setup page
        if (location.pathname !== '/profile-setup') {
          console.log("Checking if profile is complete");
          const isComplete = await checkProfileComplete();
          console.log("Profile complete check result:", isComplete);
          if (!isComplete) {
            console.log("Profile not complete, should redirect to setup");
            setShouldRedirect('/profile-setup');
          }
        }
      }
      setIsChecking(false);
    };

    checkAuth();
  }, [user, loading, location.pathname, checkProfileComplete]);

  // Still loading or checking profile status
  if (loading || isChecking) {
    return <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
    </div>;
  }

  // Redirect to login if not authenticated
  if (!user) {
    return <Navigate to="/auth/signin" replace />;
  }

  // Redirect to profile setup if profile is not complete
  if (shouldRedirect && location.pathname !== shouldRedirect) {
    console.log("Redirecting to:", shouldRedirect);
    return <Navigate to={shouldRedirect} replace />;
  }

  // Show children once we know user is authenticated and profile check is complete
  return <>{children}</>;
};

export default ProtectedRoute;
