
import React from "react";
import SignalTable from "./SignalTable";
import { SignalData } from "./SignalCard";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import SignalStats from "./SignalStats";
import QuickUpdates from "./QuickUpdates";
import { useIsMobile } from "@/hooks/use-mobile";

interface SignalsTabProps {
  signals: SignalData[];
  onRefresh?: () => void;
  isLive?: boolean;
}

const SignalsTab = ({ signals, onRefresh, isLive = false }: SignalsTabProps) => {
  const isMobile = useIsMobile();
  
  return (
    <div className="space-y-4">
      {isMobile ? (
        <div className="space-y-4">
          <SignalTable 
            signals={signals} 
            onRefresh={onRefresh}
            isLive={isLive}
          />
          <Tabs defaultValue="stats" className="w-full">
            <TabsList className="grid w-full grid-cols-2">
              <TabsTrigger value="stats">Statistics</TabsTrigger>
              <TabsTrigger value="updates">Updates</TabsTrigger>
            </TabsList>
            <TabsContent value="stats">
              <SignalStats />
            </TabsContent>
            <TabsContent value="updates">
              <QuickUpdates />
            </TabsContent>
          </Tabs>
        </div>
      ) : (
        <div className="grid gap-4 md:grid-cols-3">
          <div className="md:col-span-2">
            <SignalTable 
              signals={signals} 
              onRefresh={onRefresh}
              isLive={isLive}
            />
          </div>
          <div>
            <div className="space-y-4">
              <SignalStats />
              <QuickUpdates />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignalsTab;
