
import React from "react";
import { PortfolioSummaryCard } from "./PortfolioSummaryCard";
import { AIInsightCard } from "./AIInsightCard";

export const PortfolioSection = () => {
  return (
    <div className="space-y-3 sm:space-y-4">
      <PortfolioSummaryCard />
      <AIInsightCard />
    </div>
  );
};
