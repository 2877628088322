
import React, { useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useBlogs } from "@/hooks/useBlogs";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { ScrollArea } from "@/components/ui/scroll-area";
import { ArrowLeft, Calendar, Clock, Tag } from "lucide-react";
import HomeHeader from "@/components/home/HomeHeader";
import { format } from "date-fns";

const BlogDetail = () => {
  const { slug } = useParams<{ slug: string }>();
  const navigate = useNavigate();
  const { useBlogQuery, useBlogsQuery } = useBlogs();

  const { data: blog, isLoading, error } = useBlogQuery(slug || "");
  const { data: relatedBlogs = [] } = useBlogsQuery();

  useEffect(() => {
    if (error) {
      navigate("/blogs", { replace: true });
    }
  }, [error, navigate]);

  // Filter related blogs by same category, excluding current blog
  const filteredRelatedBlogs = blog
    ? relatedBlogs
        .filter(
          (related) => 
            related.category === blog.category && 
            related.id !== blog.id
        )
        .slice(0, 3)
    : [];

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
        <HomeHeader />
        <main className="flex-1 flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
        </main>
      </div>
    );
  }

  if (!blog) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
        <HomeHeader />
        <main className="flex-1 container mx-auto px-4 py-12">
          <div className="text-center">
            <h1 className="text-3xl font-bold mb-4">Blog Post Not Found</h1>
            <p className="text-muted-foreground mb-6">
              The blog post you're looking for doesn't exist or has been removed.
            </p>
            <Button asChild>
              <Link to="/blogs">
                <ArrowLeft className="mr-2 h-4 w-4" />
                Back to Blogs
              </Link>
            </Button>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
      <HomeHeader />
      
      <main className="flex-1 container mx-auto px-4 py-8">
        <div className="max-w-4xl mx-auto">
          <Link 
            to="/blogs"
            className="inline-flex items-center text-sm font-medium text-muted-foreground hover:text-foreground mb-6"
          >
            <ArrowLeft className="mr-2 h-4 w-4" />
            Back to Blogs
          </Link>

          <article className="space-y-8">
            <div>
              <h1 className="text-3xl sm:text-4xl font-bold mb-4">{blog.title}</h1>
              
              <div className="flex flex-wrap items-center gap-4 text-sm text-muted-foreground mb-4">
                <Badge>{blog.category}</Badge>
                <div className="flex items-center">
                  <Calendar className="h-4 w-4 mr-1" />
                  {format(new Date(blog.published_at), 'MMMM d, yyyy')}
                </div>
                <div className="flex items-center">
                  <Clock className="h-4 w-4 mr-1" />
                  {blog.read_time} min read
                </div>
              </div>
            </div>

            {blog.image_url && (
              <img 
                src={blog.image_url} 
                alt={blog.title} 
                className="w-full h-auto rounded-lg object-cover aspect-video" 
              />
            )}

            <div 
              className="prose dark:prose-invert max-w-none"
              dangerouslySetInnerHTML={{ __html: blog.content }}
            />

            {blog.tags && blog.tags.length > 0 && (
              <div className="pt-4">
                <h3 className="text-sm font-semibold mb-2 flex items-center">
                  <Tag className="h-4 w-4 mr-1" />
                  Tags
                </h3>
                <div className="flex flex-wrap gap-2">
                  {blog.tags.map((tag) => (
                    <Badge key={tag} variant="outline">
                      <Link to={`/blogs?tag=${tag}`}>{tag}</Link>
                    </Badge>
                  ))}
                </div>
              </div>
            )}

            <div className="pt-4">
              <p className="text-sm text-muted-foreground italic">
                Written by <span className="font-medium">{blog.author}</span>
              </p>
            </div>
          </article>

          {filteredRelatedBlogs.length > 0 && (
            <>
              <Separator className="my-12" />
              
              <div className="space-y-6">
                <h2 className="text-2xl font-bold">Related Posts</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  {filteredRelatedBlogs.map((related) => (
                    <Link 
                      key={related.id} 
                      to={`/blogs/${related.slug}`}
                      className="group block"
                    >
                      <div className="border rounded-lg overflow-hidden h-full transition-all hover:shadow-md">
                        {related.image_url && (
                          <div className="h-48 overflow-hidden">
                            <img 
                              src={related.image_url} 
                              alt={related.title} 
                              className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105" 
                            />
                          </div>
                        )}
                        <div className="p-4">
                          <Badge className="mb-2">{related.category}</Badge>
                          <h3 className="font-semibold mb-2 line-clamp-2 group-hover:text-primary">
                            {related.title}
                          </h3>
                          <p className="text-sm text-muted-foreground line-clamp-3">
                            {related.summary}
                          </p>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default BlogDetail;
