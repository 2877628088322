import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { 
  BarChart2, 
  LineChart, 
  TrendingUp, 
  ChevronRight, 
  Users, 
  Shield, 
  BrainCircuit,
  Search,
  Clock,
  Zap
} from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { 
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import InteractiveHero from "@/components/home/InteractiveHero";

const scrollToElement = (elementId: string) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  }
};

const Index = () => {
  const { user } = useAuth();
  
  const handleScrollToFeatures = (e: React.MouseEvent) => {
    e.preventDefault();
    scrollToElement('features');
  };

  return (
    <div className="bg-white text-gray-900 min-h-screen">
      <header className="sticky top-0 z-50 bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <span className="font-bold text-2xl text-blue-600">MarketGuru</span>
            </div>
            
            <div className="hidden md:flex items-center space-x-8">
              <NavigationMenu>
                <NavigationMenuList>
                  <NavigationMenuItem>
                    <NavigationMenuTrigger>Features</NavigationMenuTrigger>
                    <NavigationMenuContent>
                      <ul className="grid gap-3 p-6 w-[400px] md:w-[500px] lg:w-[600px] grid-cols-2">
                        <li className="row-span-3">
                          <NavigationMenuLink asChild>
                            <a
                              className="flex h-full w-full select-none flex-col justify-end rounded-md bg-gradient-to-b from-blue-500 to-blue-700 p-6 no-underline outline-none focus:shadow-md"
                              href="#features"
                              onClick={handleScrollToFeatures}
                            >
                              <BarChart2 className="h-6 w-6 text-white mb-2" />
                              <div className="mt-4 mb-2 text-lg font-medium text-white">
                                Expert Trading Signals
                              </div>
                              <p className="text-sm leading-tight text-white/90">
                                Precise entry and exit points for daily timeframe swing trades with 93%+ accuracy
                              </p>
                            </a>
                          </NavigationMenuLink>
                        </li>
                        <li>
                          <a href="#features" onClick={handleScrollToFeatures} className="block p-3 space-y-1 rounded-md hover:bg-gray-100">
                            <div className="font-medium flex items-center">
                              <BrainCircuit className="h-4 w-4 mr-2 text-blue-600" />
                              AI Entry Optimization
                            </div>
                            <p className="text-sm text-gray-500">Our proprietary NEO Score helps time entries perfectly</p>
                          </a>
                        </li>
                        <li>
                          <a href="#features" onClick={handleScrollToFeatures} className="block p-3 space-y-1 rounded-md hover:bg-gray-100">
                            <div className="font-medium flex items-center">
                              <Shield className="h-4 w-4 mr-2 text-blue-600" />
                              Risk Management
                            </div>
                            <p className="text-sm text-gray-500">AI-optimized position sizing and stop placement</p>
                          </a>
                        </li>
                        <li>
                          <a href="#features" onClick={handleScrollToFeatures} className="block p-3 space-y-1 rounded-md hover:bg-gray-100">
                            <div className="font-medium flex items-center">
                              <Clock className="h-4 w-4 mr-2 text-blue-600" />
                              Real-time Alerts
                            </div>
                            <p className="text-sm text-gray-500">Never miss an opportunity with instant notifications</p>
                          </a>
                        </li>
                      </ul>
                    </NavigationMenuContent>
                  </NavigationMenuItem>
                  <NavigationMenuItem>
                    <Link to="/pricing" className={navigationMenuTriggerStyle()}>
                      Pricing
                    </Link>
                  </NavigationMenuItem>
                  <NavigationMenuItem>
                    <Link to="#" className={navigationMenuTriggerStyle()}>
                      Community
                    </Link>
                  </NavigationMenuItem>
                </NavigationMenuList>
              </NavigationMenu>
            </div>
            
            <div className="flex items-center justify-end md:flex-1 lg:w-0">
              {user ? (
                <Button asChild className="ml-8 bg-blue-600 hover:bg-blue-700">
                  <Link to="/app">Go to Trading Signals</Link>
                </Button>
              ) : (
                <>
                  <Link to="/auth/signin" className="whitespace-nowrap text-base font-medium text-gray-700 hover:text-gray-900">
                    Sign in
                  </Link>
                  <Button asChild className="ml-8 bg-blue-600 hover:bg-blue-700">
                    <Link to="/auth/signup">Start for free</Link>
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </header>

      <section className="py-12 sm:py-16 lg:py-20 relative bg-white overflow-hidden">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
            <div>
              <div className="inline-flex items-center px-4 py-2 rounded-full text-sm font-medium bg-blue-50 text-blue-700 mb-4">
                <Zap className="mr-1 h-4 w-4" />
                <span>New: AI-powered market analysis</span>
              </div>
              <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold tracking-tight text-gray-900 mb-6">
                <span className="block">Trading made</span>
                <span className="block text-blue-600">smarter with AI</span>
              </h1>
              <p className="text-xl text-gray-600 mb-8 max-w-xl">
                Join thousands of traders who use our expert signals and AI analysis to make better trading decisions.
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <Button asChild size="lg" className="bg-blue-600 hover:bg-blue-700 text-white">
                  <Link to="/auth/signup">Get started for free</Link>
                </Button>
                <Button asChild variant="outline" size="lg" className="border-gray-300 text-gray-700">
                  <Link to="#features" className="flex items-center">
                    Learn more <ChevronRight className="ml-2 h-4 w-4" />
                  </Link>
                </Button>
              </div>
              <div className="mt-8 flex items-center text-gray-500">
                <svg viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-green-500 mr-2">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
                <span>No credit card required</span>
                <svg viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 text-green-500 mx-2">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
                <span>7-day free trial</span>
              </div>
            </div>
            <div className="mt-10 lg:mt-0 overflow-hidden rounded-xl shadow-xl border border-gray-200">
              <div className="flex items-center bg-gray-100 px-4 py-2 border-b border-gray-200">
                <div className="flex space-x-1">
                  <div className="w-3 h-3 bg-red-500 rounded-full"></div>
                  <div className="w-3 h-3 bg-yellow-500 rounded-full"></div>
                  <div className="w-3 h-3 bg-green-500 rounded-full"></div>
                </div>
                <div className="mx-auto font-medium text-sm">
                  MarketGuru Trading Platform
                </div>
              </div>
              <div className="bg-white p-4">
                <InteractiveHero />
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section className="bg-gray-50 py-12 border-y border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            <div className="text-center">
              <p className="text-3xl font-bold text-blue-600">93%+</p>
              <p className="text-gray-600">Signal Accuracy</p>
            </div>
            <div className="text-center">
              <p className="text-3xl font-bold text-blue-600">15,000+</p>
              <p className="text-gray-600">Active Traders</p>
            </div>
            <div className="text-center">
              <p className="text-3xl font-bold text-blue-600">30+</p>
              <p className="text-gray-600">Monthly Signals</p>
            </div>
            <div className="text-center">
              <p className="text-3xl font-bold text-blue-600">Daily</p>
              <p className="text-gray-600">Market Analysis</p>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Why traders choose MarketGuru</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Our platform combines expert analysis with cutting-edge AI to give you the edge in the markets
            </p>
          </div>
          
          <div className="grid md:grid-cols-3 gap-8">
            <FeatureCard 
              icon={<BarChart2 className="h-6 w-6 text-blue-600" />}
              title="Expert Signals"
              description="Precise entry and exit points for daily timeframe swing trades with 93%+ accuracy"
            />
            <FeatureCard 
              icon={<BrainCircuit className="h-6 w-6 text-blue-600" />}
              title="AI Entry Optimization"
              description="Our proprietary NEO Score helps you find the perfect time to enter positions for maximum profit potential"
            />
            <FeatureCard 
              icon={<Shield className="h-6 w-6 text-blue-600" />}
              title="Risk Management"
              description="AI-optimized position sizing and stop placement to protect your capital"
            />
            <FeatureCard 
              icon={<LineChart className="h-6 w-6 text-blue-600" />}
              title="Portfolio Analytics"
              description="In-depth performance tracking and optimization suggestions for your portfolio"
            />
            <FeatureCard 
              icon={<Search className="h-6 w-6 text-blue-600" />}
              title="Market Scanner"
              description="Find the best opportunities across global markets with our AI-powered scanner"
            />
            <FeatureCard 
              icon={<Clock className="h-6 w-6 text-blue-600" />}
              title="Real-time Alerts"
              description="Never miss a trading opportunity with instant mobile and desktop notifications"
            />
          </div>
        </div>
      </section>
      
      <section className="py-16 bg-gray-50 border-y border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Trusted by traders worldwide</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              See what our community has achieved with MarketGuru
            </p>
          </div>
          
          <div className="grid md:grid-cols-3 gap-8">
            <TestimonialCard
              quote="After using MarketGuru for 3 months, my win rate increased from 52% to 78%. The AI analysis is incredibly accurate."
              name="Michael Roberts"
              title="Swing Trader, 4 years"
              rating={5}
            />
            <TestimonialCard
              quote="The expert signals have completely transformed my trading. I'm making consistent profits for the first time in my trading career."
              name="Sarah Johnson"
              title="Day Trader, 2 years"
              rating={5}
            />
            <TestimonialCard
              quote="What sets MarketGuru apart is the risk management. The AI helps me size my positions properly and I've reduced my drawdowns significantly."
              name="David Chen"
              title="Crypto Trader, 5 years"
              rating={4}
            />
          </div>
        </div>
      </section>
      
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">How it works</h2>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Get started in minutes and experience the power of AI-driven trading
            </p>
          </div>
          
          <div className="grid md:grid-cols-3 gap-8 mb-12">
            <div className="flex flex-col items-center text-center">
              <div className="w-16 h-16 flex items-center justify-center bg-blue-100 rounded-full text-blue-600 text-xl font-bold mb-6">1</div>
              <h3 className="text-xl font-semibold mb-3">Create an account</h3>
              <p className="text-gray-600">Sign up in seconds and tell us about your trading preferences</p>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="w-16 h-16 flex items-center justify-center bg-blue-100 rounded-full text-blue-600 text-xl font-bold mb-6">2</div>
              <h3 className="text-xl font-semibold mb-3">Connect your platform</h3>
              <p className="text-gray-600">Link your trading account or use our signals manually</p>
            </div>
            <div className="flex flex-col items-center text-center">
              <div className="w-16 h-16 flex items-center justify-center bg-blue-100 rounded-full text-blue-600 text-xl font-bold mb-6">3</div>
              <h3 className="text-xl font-semibold mb-3">Start trading smarter</h3>
              <p className="text-gray-600">Get signals, analysis, and AI insights to improve your performance</p>
            </div>
          </div>
          
          <div className="text-center">
            <Button asChild size="lg" className="bg-blue-600 hover:bg-blue-700">
              <Link to="/auth/signup">Start your 7-day free trial</Link>
            </Button>
            <p className="mt-4 text-sm text-gray-500">No credit card required. Try all features for free.</p>
          </div>
        </div>
      </section>
      
      <section className="py-16 bg-blue-600">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">Start your trading journey today</h2>
          <p className="text-xl text-blue-100 mb-8 max-w-2xl mx-auto">
            Join thousands of traders who have improved their results with MarketGuru
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Button asChild size="lg" className="bg-white text-blue-600 hover:bg-blue-50">
              <Link to="/auth/signup">Start your free trial</Link>
            </Button>
          </div>
          <p className="text-blue-200 mt-4">No credit card required. 7-day free trial.</p>
        </div>
      </section>
      
      <footer className="bg-white py-12 border-t border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="grid grid-cols-2 md:grid-cols-5 gap-8">
            <div className="col-span-2">
              <span className="font-bold text-xl text-blue-600">MarketGuru</span>
              <p className="mt-2 text-gray-600">
                The ultimate platform for traders combining expert signals with AI-powered analysis.
              </p>
              <div className="flex space-x-4 mt-4">
                <a href="#" className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Twitter</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
                <a href="#" className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">LinkedIn</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                </a>
                <a href="#" className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Instagram</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363-.416-2.427-.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.01-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.045-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
                  </svg>
                </a>
              </div>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">Product</h3>
              <ul className="mt-4 space-y-4">
                <li><a href="#features" onClick={handleScrollToFeatures} className="text-base text-gray-600 hover:text-gray-900">Features</a></li>
                <li><Link to="/pricing" className="text-base text-gray-600 hover:text-gray-900">Pricing</Link></li>
                <li><a href="#" className="text-base text-gray-600 hover:text-gray-900">API</a></li>
                <li><a href="#" className="text-base text-gray-600 hover:text-gray-900">Integrations</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">Company</h3>
              <ul className="mt-4 space-y-4">
                <li><a href="#" className="text-base text-gray-600 hover:text-gray-900">About</a></li>
                <li><a href="#" className="text-base text-gray-600 hover:text-gray-900">Blog</a></li>
                <li><a href="#" className="text-base text-gray-600 hover:text-gray-900">Careers</a></li>
                <li><a href="#" className="text-base text-gray-600 hover:text-gray-900">Press</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase">Support</h3>
              <ul className="mt-4 space-y-4">
                <li><a href="#" className="text-base text-gray-600 hover:text-gray-900">Help Center</a></li>
                <li><a href="#" className="text-base text-gray-600 hover:text-gray-900">Contact</a></li>
                <li><a href="#" className="text-base text-gray-600 hover:text-gray-900">Community</a></li>
                <li><a href="#" className="text-base text-gray-600 hover:text-gray-900">Newsletter</a></li>
              </ul>
            </div>
          </div>
          <div className="mt-12 pt-8 border-t border-gray-200 flex flex-col md:flex-row justify-between">
            <p className="text-base text-gray-500">&copy; {new Date().getFullYear()} MarketGuru. All rights reserved.</p>
            <div className="mt-4 md:mt-0 flex space-x-6">
              <a href="#" className="text-gray-500 hover:text-gray-900">Privacy</a>
              <a href="#" className="text-gray-500 hover:text-gray-900">Terms</a>
              <a href="#" className="text-gray-500 hover:text-gray-900">Cookies</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }: { 
  icon: React.ReactNode; 
  title: string; 
  description: string;
}) => {
  return (
    <div className="p-6 border border-gray-200 rounded-lg hover:shadow-md transition-shadow">
      <div className="mb-4">
        {icon}
      </div>
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

const TestimonialCard = ({ quote, name, title, rating }: { 
  quote: string; 
  name: string; 
  title: string;
  rating: number;
}) => {
  return (
    <div className="p-6 border border-gray-200 rounded-lg hover:shadow-md transition-shadow bg-white">
      <div className="flex mb-4">
        {Array.from({ length: rating }).map((_, i) => (
          <svg key={i} className="h-5 w-5 text-yellow-400" fill="currentColor" viewBox="0 0 20 20">
            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
          </svg>
        ))}
      </div>
      <p className="italic text-gray-700 mb-4">"{quote}"</p>
      <div className="flex items-center">
        <div className="h-10 w-10 rounded-full bg-blue-600 flex items-center justify-center text-white font-bold">
          {name.charAt(0)}
        </div>
        <div className="ml-3">
          <h4 className="font-semibold">{name}</h4>
          <p className="text-sm text-gray-600">{title}</p>
        </div>
      </div>
    </div>
  );
};

export default Index;
