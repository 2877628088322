
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { 
  Tabs, 
  TabsContent, 
  TabsList, 
  TabsTrigger 
} from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { LineChart, BarChart, Filter, Search, RefreshCw } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import TradingHeader from "@/components/trading/TradingHeader";
import SignalsTab from "@/components/trading/SignalsTab";
import PortfolioTab from "@/components/trading/PortfolioTab";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useSignals } from "@/hooks/useSignals";
import { useAuth } from "@/contexts/AuthContext";
import { useIsMobile } from "@/hooks/use-mobile";

const TradingPlatform = () => {
  const [activeTab, setActiveTab] = useState("signals");
  const { toast } = useToast();
  const { isAdmin } = useAuth();
  const { signals, isLoading, refetch } = useSignals();
  const isMobile = useIsMobile();

  const handleRefresh = () => {
    refetch();
    toast({
      title: "Signals updated",
      description: "Latest trading signals have been fetched",
    });
  };

  return (
    <>
      <Helmet>
        <title>Trading Platform | TradingSignals</title>
        <meta name="description" content="Access real-time trading signals and portfolio tracking with our professional trading platform." />
      </Helmet>
      
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
        <TradingHeader />

        <main className="flex-1 max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8 py-4 sm:py-8" role="main">
          <h1 className="sr-only">Trading Platform</h1>
          <ScrollArea className="w-full h-full">
            <div className="mb-6">
              <Tabs defaultValue="signals" onValueChange={setActiveTab} className="w-full">
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
                  <TabsList className="grid w-full sm:w-[400px] grid-cols-2">
                    <TabsTrigger value="signals" className="flex items-center gap-2">
                      <LineChart className="h-4 w-4" aria-hidden="true" />
                      <span>Trading Signals</span>
                    </TabsTrigger>
                    <TabsTrigger value="portfolio" className="flex items-center gap-2">
                      <BarChart className="h-4 w-4" aria-hidden="true" />
                      <span>Portfolio Tracker</span>
                    </TabsTrigger>
                  </TabsList>
                  <div className="flex items-center gap-2 w-full sm:w-auto">
                    <Button 
                      variant="outline" 
                      size={isMobile ? "sm" : "default"} 
                      onClick={handleRefresh}
                      className="flex-1 sm:flex-initial"
                      aria-label="Refresh signals"
                    >
                      <RefreshCw className="h-4 w-4 mr-2" aria-hidden="true" />
                      <span className={isMobile ? "sr-only" : ""}>Refresh</span>
                    </Button>
                    <Button 
                      variant="outline" 
                      size={isMobile ? "sm" : "default"} 
                      className="flex-1 sm:flex-initial"
                      aria-label="Filter signals"
                    >
                      <Filter className="h-4 w-4 mr-2" aria-hidden="true" />
                      <span className={isMobile ? "sr-only" : ""}>Filter</span>
                    </Button>
                    <Button 
                      variant="outline" 
                      size={isMobile ? "sm" : "default"} 
                      className="flex-1 sm:flex-initial"
                      aria-label="Search signals"
                    >
                      <Search className="h-4 w-4 mr-2" aria-hidden="true" />
                      <span className={isMobile ? "sr-only" : ""}>Search</span>
                    </Button>
                  </div>
                </div>

                <TabsContent value="signals">
                  {isLoading ? (
                    <div className="flex justify-center p-8" aria-live="polite">
                      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary" 
                           role="status" aria-label="Loading signals">
                        <span className="sr-only">Loading signals...</span>
                      </div>
                    </div>
                  ) : (
                    <SignalsTab 
                      signals={signals} 
                      onRefresh={handleRefresh}
                      isLive={false}
                    />
                  )}
                </TabsContent>

                <TabsContent value="portfolio">
                  <PortfolioTab />
                </TabsContent>
              </Tabs>
            </div>
          </ScrollArea>
        </main>
      </div>
    </>
  );
};

export default TradingPlatform;
