
import React from "react";
import { motion } from "framer-motion";
import { Badge } from "@/components/ui/badge";
import { TradingSignal } from "./models";
import { TrendingUp, TrendingDown, ArrowUpRight, ArrowDownRight } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";

interface SignalCardProps {
  signal: TradingSignal;
  isNew?: boolean;
}

export const SignalCard = ({ signal, isNew }: SignalCardProps) => {
  const isBuy = signal.signal === "Buy";
  const isMobile = useIsMobile();
  
  return (
    <motion.div
      initial={isNew ? { opacity: 0, y: -20 } : { opacity: 1, y: 0 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.3 }}
      className="signal-card relative border rounded-md overflow-hidden"
    >
      {isNew && (
        <div className="absolute -right-5 -top-5 bg-blue-600 text-white transform rotate-45 w-20 py-1 text-xs font-bold text-center shadow-md">
          NEW
        </div>
      )}
      <div className="flex items-center justify-between p-3 border-b">
        <div className="flex items-center space-x-3">
          <div className={`w-8 h-8 sm:w-10 sm:h-10 rounded-md flex items-center justify-center ${isBuy ? 'bg-green-100 text-green-600' : 'bg-red-100 text-red-600'}`}>
            {isBuy ? <TrendingUp className="h-4 w-4 sm:h-5 sm:w-5" /> : <TrendingDown className="h-4 w-4 sm:h-5 sm:w-5" />}
          </div>
          <div>
            <h3 className="font-bold text-base sm:text-lg">{signal.symbol}</h3>
            <div className="flex items-center">
              <Badge variant={isBuy ? "success" : "destructive"} className="mr-1 text-xs">
                {isBuy ? "BUY" : "SELL"}
              </Badge>
              <span className="text-xs text-muted-foreground">AI Score: {signal.aiScore}</span>
            </div>
          </div>
        </div>
        <div className={`text-right ${isBuy ? 'text-green-600' : 'text-red-600'}`}>
          <div className="font-bold text-base sm:text-lg flex items-center justify-end">
            {isBuy ? (
              <ArrowUpRight className="h-3 w-3 sm:h-4 sm:w-4 mr-1" />
            ) : (
              <ArrowDownRight className="h-3 w-3 sm:h-4 sm:w-4 mr-1" />
            )}
            {isBuy ? "+" : ""}{signal.pnl}%
          </div>
          <div className="text-xs text-muted-foreground">
            Price: ${signal.entryPrice}
          </div>
        </div>
      </div>
      <div className="p-2 sm:p-3 text-xs sm:text-sm grid grid-cols-3 gap-1 sm:gap-2">
        <div>
          <div className="text-muted-foreground text-xs">Entry</div>
          <div className="font-medium">${signal.entryPrice}</div>
        </div>
        <div>
          <div className="text-muted-foreground text-xs">Stop Loss</div>
          <div className="font-medium">${signal.stopLoss}</div>
        </div>
        <div>
          <div className="text-muted-foreground text-xs">Target</div>
          <div className="font-medium">${signal.targetPrice}</div>
        </div>
      </div>
    </motion.div>
  );
};
