
import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from "recharts";
import { usePortfolio } from "@/hooks/usePortfolio";
import { ScrollArea } from "@/components/ui/scroll-area";

const COLORS = [
  "#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884D8", 
  "#82CA9D", "#FF6B6B", "#6B66FF", "#FFD166", "#06D6A0"
];

const PortfolioCharts = () => {
  const { holdings, isLoading } = usePortfolio();

  // Generate allocation data for pie chart
  const allocationData = holdings
    .filter(holding => holding.allocation && holding.allocation > 0)
    .map(holding => ({
      name: holding.symbol,
      value: holding.allocation
    }));

  // In a real app, we might group smaller holdings together
  // For now, we'll just use the top 5 holdings and group the rest
  let pieData = allocationData;
  if (allocationData.length > 5) {
    const sortedData = [...allocationData].sort((a, b) => (b.value || 0) - (a.value || 0));
    const top5 = sortedData.slice(0, 5);
    const others = sortedData.slice(5);
    const othersSum = others.reduce((sum, item) => sum + (item.value || 0), 0);
    pieData = [
      ...top5,
      { name: "Others", value: othersSum }
    ];
  }

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white dark:bg-gray-800 p-3 border rounded shadow text-xs">
          <p className="font-medium">{payload[0].name}</p>
          <p>{`Allocation: ${payload[0].value.toFixed(2)}%`}</p>
        </div>
      );
    }
    return null;
  };

  if (isLoading) {
    return (
      <Card>
        <CardHeader className="pb-2">
          <CardTitle>Portfolio Allocation</CardTitle>
          <CardDescription>
            Asset distribution across your portfolio
          </CardDescription>
        </CardHeader>
        <CardContent className="flex justify-center items-center h-[300px]">
          <div className="animate-pulse rounded-full h-40 w-40 border-8 border-gray-200"></div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader className="pb-2">
        <CardTitle>Portfolio Allocation</CardTitle>
        <CardDescription>
          Asset distribution across your portfolio
        </CardDescription>
      </CardHeader>
      <CardContent>
        {holdings.length > 0 ? (
          <div className="h-[300px] flex flex-col md:flex-row gap-4">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={pieData}
                  cx="50%"
                  cy="50%"
                  innerRadius={70}
                  outerRadius={90}
                  paddingAngle={1}
                  dataKey="value"
                  label={({ name }) => name}
                  labelLine={false}
                >
                  {pieData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
                <Legend verticalAlign="bottom" height={36} />
              </PieChart>
            </ResponsiveContainer>
            
            <ScrollArea className="w-full md:w-56 border rounded p-4 h-[200px] md:h-auto">
              <p className="font-medium text-sm mb-2">Holdings</p>
              <ul className="space-y-2">
                {allocationData.map((item, index) => (
                  <li key={index} className="flex items-center justify-between text-xs">
                    <div className="flex items-center gap-2">
                      <div
                        className="w-3 h-3 rounded-full"
                        style={{ backgroundColor: COLORS[index % COLORS.length] }}
                      ></div>
                      <span>{item.name}</span>
                    </div>
                    <span>{item.value.toFixed(2)}%</span>
                  </li>
                ))}
              </ul>
            </ScrollArea>
          </div>
        ) : (
          <div className="h-[300px] flex items-center justify-center text-center">
            <p className="text-muted-foreground">
              Add positions to your portfolio to see allocation charts
            </p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default PortfolioCharts;
