
import React, { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/contexts/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import CreateSignalForm from "@/components/admin/CreateSignalForm";
import SignalList from "@/components/admin/SignalList";
import TradingHeader from "@/components/trading/TradingHeader";
import QuickUpdatesManagement from "@/components/admin/QuickUpdatesManagement";
import { AlertCircle, Book, FileEdit } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";

const SignalManagement = () => {
  const [activeTab, setActiveTab] = useState("list");
  const [signalToEdit, setSignalToEdit] = useState<string | null>(null);
  const { isAdmin, loading } = useAuth();
  const { toast } = useToast();
  const navigate = useNavigate();

  // Redirect non-admin users
  React.useEffect(() => {
    if (!loading && !isAdmin) {
      toast({
        title: "Access Denied",
        description: "You do not have permission to access this page.",
        variant: "destructive",
      });
      navigate("/app");
    }
  }, [isAdmin, loading, navigate, toast]);

  const handleEditSignal = (signalId: string) => {
    setSignalToEdit(signalId);
    setActiveTab("create");
  };

  const handleCreateNew = () => {
    setSignalToEdit(null);
    setActiveTab("create");
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!isAdmin) {
    return null; // Will be redirected by the useEffect
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
      <TradingHeader />
      
      <main className="flex-1 max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8 py-6">
        <ScrollArea className="w-full h-full">
          <div className="mb-6">
            <Alert className="mb-6">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Admin Access</AlertTitle>
              <AlertDescription>
                You have admin privileges to manage trading signals. Changes will be immediately visible to all users.
              </AlertDescription>
            </Alert>
            
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-2xl font-bold">Signal Management</h1>
              <div className="flex gap-2">
                <Button asChild variant="outline">
                  <Link to="/admin/blogs">
                    <Book className="mr-2 h-4 w-4" />
                    Manage Blog Posts
                  </Link>
                </Button>
              </div>
            </div>
            
            <Tabs defaultValue="list" value={activeTab} onValueChange={setActiveTab} className="w-full">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
                <TabsList className="grid w-full sm:w-[400px] grid-cols-3">
                  <TabsTrigger value="list">
                    Manage Signals
                  </TabsTrigger>
                  <TabsTrigger value="create">
                    {signalToEdit ? "Edit Signal" : "Create Signal"}
                  </TabsTrigger>
                  <TabsTrigger value="updates">
                    Quick Updates
                  </TabsTrigger>
                </TabsList>
              </div>

              <TabsContent value="list">
                <Card>
                  <CardHeader>
                    <CardTitle>Trading Signals</CardTitle>
                    <CardDescription>
                      Manage existing trading signals or create new ones.
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <SignalList onEdit={handleEditSignal} onCreateNew={handleCreateNew} />
                  </CardContent>
                </Card>
              </TabsContent>

              <TabsContent value="create">
                <Card>
                  <CardHeader>
                    <CardTitle>{signalToEdit ? "Edit Signal" : "Create New Signal"}</CardTitle>
                    <CardDescription>
                      {signalToEdit 
                        ? "Update an existing trading signal with new information."
                        : "Create a new trading signal with entry price, targets, and analysis."}
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <CreateSignalForm 
                      signalId={signalToEdit} 
                      onSuccess={() => {
                        setSignalToEdit(null);
                        setActiveTab("list");
                      }}
                    />
                  </CardContent>
                </Card>
              </TabsContent>

              <TabsContent value="updates">
                <QuickUpdatesManagement />
              </TabsContent>
            </Tabs>
          </div>
        </ScrollArea>
      </main>
    </div>
  );
};

export default SignalManagement;
