
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/contexts/AuthContext";
import { useToast } from "@/hooks/use-toast";
import { useBlogs } from "@/hooks/useBlogs";
import { BlogPost, BLOG_CATEGORIES } from "@/types/blog";
import TradingHeader from "@/components/trading/TradingHeader";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { AlertCircle, Eye, EyeOff, Pencil, Plus, Trash2 } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import RichTextEditor from "@/components/blog/RichTextEditor";
import { format } from "date-fns";
import BlogCard from "@/components/blog/BlogCard";

const initialBlog = {
  title: "",
  content: "",
  summary: "",
  image_url: "",
  author: "",
  category: BLOG_CATEGORIES[0],
  tags: [],
  slug: "",
  read_time: 5,
  published_at: new Date().toISOString(),
};

const BlogManagement = () => {
  const { isAdmin, loading, user } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [activeTab, setActiveTab] = useState("list");
  const [blogToEdit, setBlogToEdit] = useState<BlogPost | null>(null);
  const [blogForm, setBlogForm] = useState(initialBlog);
  const [tagInput, setTagInput] = useState("");
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [blogToDelete, setBlogToDelete] = useState<string | null>(null);

  const { 
    useBlogsQuery,
    useCreateBlogMutation,
    useUpdateBlogMutation,
    useDeleteBlogMutation,
    usePublishBlogMutation,
    useUnpublishBlogMutation
  } = useBlogs();

  const { data: blogs = [], isLoading: blogsLoading } = useBlogsQuery();
  const createBlogMutation = useCreateBlogMutation();
  const updateBlogMutation = useUpdateBlogMutation();
  const deleteBlogMutation = useDeleteBlogMutation();
  const publishBlogMutation = usePublishBlogMutation();
  const unpublishBlogMutation = useUnpublishBlogMutation();

  // Redirect non-admin users
  useEffect(() => {
    if (!loading && !isAdmin) {
      toast({
        title: "Access Denied",
        description: "You do not have permission to access this page.",
        variant: "destructive",
      });
      navigate("/app");
    }
  }, [isAdmin, loading, navigate, toast]);

  const handleEditBlog = (blog: BlogPost) => {
    setBlogToEdit(blog);
    setBlogForm({
      title: blog.title,
      content: blog.content,
      summary: blog.summary,
      image_url: blog.image_url || "",
      author: blog.author,
      category: blog.category,
      tags: blog.tags || [],
      slug: blog.slug,
      read_time: blog.read_time,
      published_at: blog.published_at,
    });
    setActiveTab("create");
  };

  const handleCreateNew = () => {
    setBlogToEdit(null);
    setBlogForm(initialBlog);
    setActiveTab("create");
  };

  const handleAddTag = () => {
    if (tagInput.trim() && !blogForm.tags.includes(tagInput.trim())) {
      setBlogForm({
        ...blogForm,
        tags: [...blogForm.tags, tagInput.trim()],
      });
      setTagInput("");
    }
  };

  const handleRemoveTag = (tag: string) => {
    setBlogForm({
      ...blogForm,
      tags: blogForm.tags.filter((t) => t !== tag),
    });
  };

  const handlePublishBlog = (id: string) => {
    publishBlogMutation.mutate(id);
  };

  const handleUnpublishBlog = (id: string) => {
    unpublishBlogMutation.mutate(id);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (blogToEdit) {
      updateBlogMutation.mutate({
        id: blogToEdit.id,
        ...blogForm,
      });
    } else {
      createBlogMutation.mutate(blogForm);
    }
    setActiveTab("list");
  };

  const confirmDelete = (id: string) => {
    setBlogToDelete(id);
    setIsDeleteDialogOpen(true);
  };

  const executeDelete = () => {
    if (blogToDelete) {
      deleteBlogMutation.mutate(blogToDelete);
      setIsDeleteDialogOpen(false);
      setBlogToDelete(null);
    }
  };

  const previewBlog: BlogPost = {
    id: blogToEdit?.id || "preview",
    title: blogForm.title || "Preview Title",
    content: blogForm.content || "<p>Preview content</p>",
    summary: blogForm.summary || "Preview summary",
    image_url: blogForm.image_url,
    author: blogForm.author || (user?.email?.split('@')[0] || "Author"),
    category: blogForm.category,
    tags: blogForm.tags,
    slug: blogForm.slug || "preview-slug",
    read_time: blogForm.read_time,
    published_at: blogForm.published_at,
    is_published: true,
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!isAdmin) {
    return null; // Will be redirected by the useEffect
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col">
      <TradingHeader />
      
      <main className="flex-1 max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8 py-6">
        <ScrollArea className="w-full h-full">
          <div className="mb-6">
            <Alert className="mb-6">
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>Admin Access</AlertTitle>
              <AlertDescription>
                You can manage blog posts here. Create, edit, and publish content for the blog section.
              </AlertDescription>
            </Alert>
            
            <Tabs defaultValue="list" value={activeTab} onValueChange={setActiveTab} className="w-full">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
                <TabsList className="grid w-full sm:w-[400px] grid-cols-2">
                  <TabsTrigger value="list">
                    Manage Blogs
                  </TabsTrigger>
                  <TabsTrigger value="create">
                    {blogToEdit ? "Edit Blog" : "Create Blog"}
                  </TabsTrigger>
                </TabsList>
              </div>

              <TabsContent value="list">
                <Card>
                  <CardHeader className="flex flex-row items-center justify-between">
                    <div>
                      <CardTitle>Blog Posts</CardTitle>
                      <CardDescription>
                        Manage existing blog posts or create new ones.
                      </CardDescription>
                    </div>
                    <Button onClick={handleCreateNew}>
                      <Plus className="mr-2 h-4 w-4" />
                      Create New
                    </Button>
                  </CardHeader>
                  <CardContent>
                    {blogsLoading ? (
                      <div className="flex justify-center py-8">
                        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary"></div>
                      </div>
                    ) : blogs.length === 0 ? (
                      <div className="text-center py-8 text-muted-foreground">
                        No blog posts found. Create your first one!
                      </div>
                    ) : (
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead>Title</TableHead>
                            <TableHead>Category</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Published</TableHead>
                            <TableHead className="text-right">Actions</TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {blogs.map((blog) => (
                            <TableRow key={blog.id}>
                              <TableCell className="font-medium max-w-[200px] truncate">
                                {blog.title}
                              </TableCell>
                              <TableCell>
                                <Badge variant="outline">{blog.category}</Badge>
                              </TableCell>
                              <TableCell>
                                {blog.is_published ? (
                                  <Badge className="bg-green-500">Published</Badge>
                                ) : (
                                  <Badge variant="outline">Draft</Badge>
                                )}
                              </TableCell>
                              <TableCell>
                                {blog.published_at ? format(new Date(blog.published_at), 'MMM d, yyyy') : 'Not published'}
                              </TableCell>
                              <TableCell className="text-right">
                                <div className="flex justify-end gap-2">
                                  <Button variant="outline" size="icon" asChild>
                                    <a href={`/blogs/${blog.slug}`} target="_blank" rel="noopener noreferrer">
                                      <Eye className="h-4 w-4" />
                                    </a>
                                  </Button>
                                  <Button variant="outline" size="icon" onClick={() => handleEditBlog(blog)}>
                                    <Pencil className="h-4 w-4" />
                                  </Button>
                                  {blog.is_published ? (
                                    <Button variant="outline" size="icon" onClick={() => handleUnpublishBlog(blog.id)}>
                                      <EyeOff className="h-4 w-4" />
                                    </Button>
                                  ) : (
                                    <Button variant="outline" size="icon" onClick={() => handlePublishBlog(blog.id)}>
                                      <Eye className="h-4 w-4" />
                                    </Button>
                                  )}
                                  <Button variant="destructive" size="icon" onClick={() => confirmDelete(blog.id)}>
                                    <Trash2 className="h-4 w-4" />
                                  </Button>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </CardContent>
                </Card>
              </TabsContent>

              <TabsContent value="create">
                <Card>
                  <CardHeader>
                    <CardTitle>{blogToEdit ? "Edit Blog Post" : "Create New Blog Post"}</CardTitle>
                    <CardDescription>
                      {blogToEdit 
                        ? "Update an existing blog post with new content."
                        : "Create a new blog post with rich content and publish when ready."}
                    </CardDescription>
                  </CardHeader>
                  <CardContent>
                    <form onSubmit={handleSubmit} className="space-y-6">
                      <div className="space-y-4">
                        <div>
                          <Label htmlFor="title">Title</Label>
                          <Input
                            id="title"
                            value={blogForm.title}
                            onChange={(e) => setBlogForm({ ...blogForm, title: e.target.value })}
                            placeholder="Enter blog title"
                            required
                          />
                        </div>
                        
                        <div>
                          <Label htmlFor="summary">Summary</Label>
                          <Textarea
                            id="summary"
                            value={blogForm.summary}
                            onChange={(e) => setBlogForm({ ...blogForm, summary: e.target.value })}
                            placeholder="Brief summary of the blog post"
                            required
                          />
                        </div>

                        <div>
                          <Label htmlFor="image_url">Cover Image URL</Label>
                          <Input
                            id="image_url"
                            value={blogForm.image_url}
                            onChange={(e) => setBlogForm({ ...blogForm, image_url: e.target.value })}
                            placeholder="https://example.com/image.jpg"
                          />
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                          <div>
                            <Label htmlFor="author">Author</Label>
                            <Input
                              id="author"
                              value={blogForm.author}
                              onChange={(e) => setBlogForm({ ...blogForm, author: e.target.value })}
                              placeholder="Author name"
                              required
                            />
                          </div>
                          <div>
                            <Label htmlFor="category">Category</Label>
                            <Select
                              value={blogForm.category}
                              onValueChange={(value) => setBlogForm({ ...blogForm, category: value })}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Select category" />
                              </SelectTrigger>
                              <SelectContent>
                                {BLOG_CATEGORIES.map((category) => (
                                  <SelectItem key={category} value={category}>
                                    {category}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </div>
                        </div>

                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                          <div>
                            <Label htmlFor="read_time">Read Time (minutes)</Label>
                            <Input
                              id="read_time"
                              type="number"
                              min="1"
                              value={blogForm.read_time}
                              onChange={(e) => setBlogForm({ ...blogForm, read_time: parseInt(e.target.value) || 1 })}
                              required
                            />
                          </div>
                          <div>
                            <Label htmlFor="slug">Slug (URL path)</Label>
                            <Input
                              id="slug"
                              value={blogForm.slug}
                              onChange={(e) => setBlogForm({ ...blogForm, slug: e.target.value })}
                              placeholder="blog-post-url"
                            />
                            <p className="text-sm text-muted-foreground mt-1">
                              Leave empty to auto-generate from title
                            </p>
                          </div>
                        </div>

                        <div>
                          <Label htmlFor="tags">Tags</Label>
                          <div className="flex gap-2 mb-2">
                            <Input
                              id="tagInput"
                              value={tagInput}
                              onChange={(e) => setTagInput(e.target.value)}
                              placeholder="Add a tag"
                              onKeyDown={(e) => e.key === 'Enter' && (e.preventDefault(), handleAddTag())}
                            />
                            <Button type="button" onClick={handleAddTag} variant="outline">
                              Add Tag
                            </Button>
                          </div>
                          <div className="flex flex-wrap gap-2 mt-2">
                            {blogForm.tags.map((tag) => (
                              <Badge key={tag} variant="secondary" className="flex items-center gap-1">
                                {tag}
                                <button
                                  type="button"
                                  onClick={() => handleRemoveTag(tag)}
                                  className="text-muted-foreground hover:text-foreground ml-1"
                                >
                                  ×
                                </button>
                              </Badge>
                            ))}
                          </div>
                        </div>

                        <div>
                          <Label htmlFor="content">Content</Label>
                          <RichTextEditor
                            content={blogForm.content}
                            onChange={(content) => setBlogForm({ ...blogForm, content })}
                          />
                        </div>
                      </div>

                      <div className="flex justify-between">
                        <Button type="button" variant="outline" onClick={() => setActiveTab("list")}>
                          Cancel
                        </Button>
                        <div className="flex gap-2">
                          <Button type="button" variant="outline" onClick={() => setIsPreviewOpen(true)}>
                            Preview
                          </Button>
                          <Button type="submit">
                            {blogToEdit ? "Update" : "Create"}
                          </Button>
                        </div>
                      </div>
                    </form>
                  </CardContent>
                </Card>
              </TabsContent>
            </Tabs>
          </div>
        </ScrollArea>
      </main>

      {/* Preview Dialog */}
      <Dialog open={isPreviewOpen} onOpenChange={setIsPreviewOpen}>
        <DialogContent className="max-w-4xl h-[80vh] flex flex-col">
          <DialogHeader>
            <DialogTitle>Blog Preview</DialogTitle>
            <DialogDescription>
              This is how your blog post will appear to readers
            </DialogDescription>
          </DialogHeader>
          
          <ScrollArea className="flex-1 -mx-6 px-6">
            <div className="space-y-6 py-4">
              <BlogCard post={previewBlog} featured={true} />
              
              <div className="mt-8 space-y-8">
                <h1 className="text-3xl font-bold">{previewBlog.title}</h1>
                
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Badge>{previewBlog.category}</Badge>
                    <span className="text-sm text-muted-foreground">
                      {format(new Date(previewBlog.published_at), 'MMMM d, yyyy')}
                    </span>
                  </div>
                  <span className="text-sm text-muted-foreground">
                    {previewBlog.read_time} min read
                  </span>
                </div>
                
                {previewBlog.image_url && (
                  <img 
                    src={previewBlog.image_url} 
                    alt={previewBlog.title} 
                    className="w-full h-auto aspect-video object-cover rounded-lg"
                  />
                )}
                
                <div className="prose dark:prose-invert max-w-none" dangerouslySetInnerHTML={{ __html: previewBlog.content }} />
                
                <div className="flex flex-wrap gap-2 mt-6">
                  {previewBlog.tags.map((tag) => (
                    <Badge key={tag} variant="outline">{tag}</Badge>
                  ))}
                </div>
                
                <div className="border-t pt-4 mt-8">
                  <p className="text-sm text-muted-foreground">
                    Written by <span className="font-medium">{previewBlog.author}</span>
                  </p>
                </div>
              </div>
            </div>
          </ScrollArea>
          
          <DialogFooter>
            <Button onClick={() => setIsPreviewOpen(false)}>Close Preview</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Blog Post</DialogTitle>
            <DialogDescription>
              Are you sure you want to delete this blog post? This action cannot be undone.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsDeleteDialogOpen(false)}>
              Cancel
            </Button>
            <Button variant="destructive" onClick={executeDelete}>
              Delete
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default BlogManagement;
