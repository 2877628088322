
import React from "react";
import { SignalListSection } from "./hero/SignalListSection";
import { PortfolioSection } from "./hero/PortfolioSection";
import { useIsMobile } from "@/hooks/use-mobile";

const InteractiveHero = () => {
  const isMobile = useIsMobile();
  
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-12 items-start">
      <SignalListSection />
      <PortfolioSection />
    </div>
  );
};

export default InteractiveHero;
