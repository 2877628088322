
import React from "react";
import PortfolioSummary from "./PortfolioSummary";
import PortfolioHoldings from "./PortfolioHoldings";
import PortfolioCharts from "./PortfolioCharts";
import PortfolioRiskAnalysis from "./PortfolioRiskAnalysis";

const PortfolioTab = () => {
  return (
    <div className="space-y-6">
      <PortfolioSummary />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <PortfolioRiskAnalysis />
        <PortfolioCharts />
      </div>
      <PortfolioHoldings />
    </div>
  );
};

export default PortfolioTab;
