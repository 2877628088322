
import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Zap, Bell } from "lucide-react";
import { demoSignals } from "./models";
import { SignalCard } from "./SignalCard";

export const SignalListSection = () => {
  const [activeSignals, setActiveSignals] = useState(demoSignals.slice(0, 3));
  const [newSignalAdded, setNewSignalAdded] = useState(false);
  
  // Function to add a new random signal
  const addNewSignal = () => {
    const availableSignals = demoSignals.filter(
      signal => !activeSignals.find(active => active.id === signal.id)
    );
    
    if (availableSignals.length > 0) {
      const randomSignal = availableSignals[Math.floor(Math.random() * availableSignals.length)];
      setActiveSignals([randomSignal, ...activeSignals.slice(0, 2)]);
      setNewSignalAdded(true);
      
      // Reset new signal indicator after animation
      setTimeout(() => {
        setNewSignalAdded(false);
      }, 3000);
    }
  };
  
  // Add a new signal every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      addNewSignal();
    }, 10000);
    
    return () => clearInterval(interval);
  }, [activeSignals]);
  
  return (
    <div className="space-y-3 sm:space-y-4">
      <Badge variant="pill" className="mb-2 sm:mb-4">
        <Zap className="mr-1 h-3 w-3 sm:h-4 sm:w-4" />
        <span className="text-xs sm:text-sm font-medium">Live Trading Signals</span>
      </Badge>
      
      {/* Fixed height container with overflow hidden to prevent layout shifts */}
      <div 
        className="space-y-3 sm:space-y-4 overflow-hidden" 
        style={{ height: '460px' }}
      >
        <AnimatePresence>
          {activeSignals.map((signal, index) => (
            <SignalCard 
              key={signal.id} 
              signal={signal} 
              isNew={index === 0 && newSignalAdded}
            />
          ))}
        </AnimatePresence>
      </div>
      
      <Button 
        onClick={addNewSignal}
        className="w-full mt-2 bg-blue-600 hover:bg-blue-700 text-[10px] xs:text-xs sm:text-sm h-8 sm:h-10"
      >
        <Bell className="mr-2 h-3 w-3 sm:h-4 sm:w-4" />
        Get New Signal
      </Button>
    </div>
  );
};
